import { createAsyncThunk,createSlice,isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
    catchAsync,
    detectError,
    handleLoadingErrorParamsForAsycThunk,
    reduxToolKitCaseBuilder,
} from "../detectError";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// Start Application Slices
///////////////////////////////////////////////////


export const getInvitationsAsyncThunk = createAsyncThunk(
    "invitation/getInvitationsAsyncThunk",
    catchAsync(async (params, _) => {
        const response = await ApiRequests.getInvitations(params);
        return response?.data;
    })
)
export const getJobInvitationAsyncThunk = createAsyncThunk(
    "invitation/getJobInvitationAsyncThunk",
    catchAsync(async ({jobId, callBack}, _) => {
        const response = await ApiRequests.getJobInvitation(jobId);
        console.log("Object.keys(response.data)", Object.keys(response.data))
        if (callBack) callBack(Object.keys(response.data));
        return response?.data;
    })
)

export const acceptJobInvitationAsyncThunk = createAsyncThunk(
    "invitation/acceptJobInvitationAsyncThunk",
    catchAsync(async ({jobId, callBack}, _) => {
        const response = await ApiRequests.acceptInvitation(jobId);
        if (callBack) callBack(response.data);
        return response?.data;
    })
)

const initialState = {
    invitations : {
        page: 1,
        results: [],
        totalPages: 1,
        totalResults: 0,
        limit : 10,
    },
    invitation:null,
    errors: {},
    loadings : {},
    errorMessages : {},
    errorCodes : {},
    paramsForThunk : {},
}

const invitationSlice = createSlice({
    name: "invitation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getInvitationsAsyncThunk.fulfilled, (state, action) => {
            if (action.payload?.page > 1) {
                state.invitations = {
                    ...action.payload,
                    invitations: state?.invitations?.results.concat(
                        action?.payload?.results
                    ),
                };
            } else {
                state.invitations = action.payload;
            }
        })
        .addCase(getInvitationsAsyncThunk.pending, (state, action) => {
            if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
                state.invitations = {
                    page: 1,
                    results: [],
                    totalPages: 1,
                };
            }
        })
        .addMatcher(
            isAnyOf(
                ...reduxToolKitCaseBuilder([
                    getInvitationsAsyncThunk
                ])
            ),
            handleLoadingErrorParamsForAsycThunk
        )
    }
})

export default invitationSlice.reducer

    