import React, { useEffect, useState } from "react";
import MyJobsPageHeader from "../components/MyJobsPageHeader";
import NoJobsComponent from "../components/NoJobsComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getHiredJobsAsyncThunk,
  getMyJobsAsyncThunk,
  getMyJobsCountAsyncThunk,
} from "../redux/pagesSlices/jobSlice";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import MyJob from "../components/MyJob";
import noJobsImg from "../assets/images/icons/no-jobs-img.svg";
import {getId, returnArray, setValueIfExist} from "../utils/utils";
import {Link} from "react-router-dom";
import {parseImagePathToURL} from "../utils/globalFunctions";
import JobsImg from "../assets/images/job-img-1.svg";
import smsIcon from "../assets/images/icons/sms-icon.svg";

export default function MyJobsPage() {
  const [activeJobsTab, setActiveJobsTabs] = useState("all");
  const activeJobTabsHandler = (tab) => {
    setActiveJobsTabs(tab);
  };
  const dispatch = useDispatch();

  const { jobsCount } = useSelector((s) => s.job);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");

  const jobParams = {
    ...setValueIfExist({
      sortBy,
      populate: "brandId,applicationId,jobId",
      search,
    }),
  };

  useEffect(() => {
    // dispatch(getHiredJobsAsyncThunk({ sortBy, populate: "brandId" }));
    dispatch(getMyJobsAsyncThunk(jobParams));
  }, [dispatch]);

  useEffect(() => {
    const params = {
      ...jobParams,
    };
    if (search) params.search = search;
    if (activeJobsTab && activeJobsTab !== "all") params.status = activeJobsTab;
    dispatch(getMyJobsAsyncThunk({ ...params }));

    dispatch(
      getMyJobsCountAsyncThunk({
        ...setValueIfExist({
          search,
          populate: "jobId",
        }),
      })
    );
  }, [dispatch, sortBy, search, activeJobsTab]);

  const Tabs = () => (
    <div className="top-bar-wrapper">
      <ul className="tabs-wrapper">
        <li>
          <button
            className={`custom-btn ${activeJobsTab === "all" ? "active" : ""}`}
            onClick={() => activeJobTabsHandler("all")}
          >
            All <span className="num">{jobsCount?.all || 0}</span>
          </button>{" "}
        </li>
        <li>
          <button
            className={`custom-btn ${
              activeJobsTab === "pending" ? "active" : ""
            }`}
            onClick={() => activeJobTabsHandler("pending")}
          >
            In Progress <span className="num">{jobsCount?.pending || 0}</span>
          </button>
        </li>
        <li>
          <button
            className={`custom-btn ${
              activeJobsTab === "completed" ? "active" : ""
            }`}
            onClick={() => activeJobTabsHandler("completed")}
          >
            Completed <span className="num">{jobsCount?.completed || 0}</span>
          </button>
        </li>
      </ul>
    </div>
  );
  const Parent = ({ children }) => (
    <div className="inner-page-section">
      <Tabs />
      <div className="my-jobs-listings ">
        <div className="table-wrapper over_flow">
          <div className="my-proposals-listings">
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: "start",
                        paddingLeft: 133,
                      }}
                    >
                      <strong className="white small">Brand Name</strong>
                    </th>
                    <th style={{ textAlign: "start" }}>
                      <strong className="white small">Status</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>{children} </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const LoaderParent = ({ children }) => (
    <div className="inner-page-section">
      <Tabs />
      <div className="my-jobs-listings ">
        <div className="table-wrapper over_flow">
          <div className="my-proposals-listings">
            <div className="table-wrapper">
              <table>
                
                <tbody>{children}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
      <div className="inner-page-wrapper active m-0">
        <div className="my-jobs-page">
          <MyJobsPageHeader
              search={search}
              sortBy={sortBy}
              handleSearchChange={(e) => setSearch(e.target.value)}
              title={"My Jobs"}
              containerFluid={true}
              setSortBy={setSortBy}
          />
          {/* {hasListings ? ( */}
          <>
            {/* <MyJobsListings tab={activeJobsTab} /> */}
            <div className="table-wrapper">

              {/*<table>

                <thead>
                <tr>
                  <th
                      style={{
                        textAlign: "start",
                        paddingLeft: 133,
                      }}
                  >
                    <strong className="white small">Brand Name</strong>
                  </th>
                  <th style={{textAlign: "start"}}>
                    <strong className="white small">Status</strong>
                  </th>
                </tr>
                </thead>
                <tbody>


                <tr>
                  <td>
                    <div className="main-wrapper">
                      <div className="img-wrapper">
                        <Link to={`#`}>
                          <img
                              src={JobsImg}
                              alt="JobsImg"
                          />
                        </Link>
                      </div>
                      <div className="text-wrapper">
                        <p className="dark">
                          <Link to={`#`}>
                            Demo
                          </Link>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="main-status-wrapper">
                      <ul className="status-list">
                        <li className={"complete"}>
                          <span className="status"></span> Request sample{" "}
                        </li>
                        <li className="active">
                          <span className="status"></span> Confirm sam[ple{" "}
                        </li>
                        <li className={"complete"}>
                          <span className="status"></span>Create content{" "}
                        </li>
                        <li
                            className={"complete"}
                        >
                          <span className="status"></span> Brand review & Completion
                        </li>
                        <li
                            className={"complete"}
                        >
                          <span className="status"></span> Payout
                        </li>
                      </ul>
                      <div className="btns-wrapper">
                        <Link
                            to={`#`}
                            className="btn-style light"
                        >
                          {" "}
                          <img src={smsIcon} alt="smsIcon"/> Message Brand
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>

                </tbody>
              </table>*/}


            </div>
            <ProviderErrorLoadingPagination
                reducer="job"
                action="getMyJobsAsyncThunk"
                asyncThunk={getMyJobsAsyncThunk}
                dataKey="myJobs"
                Component={MyJob}
                emptyMessage="No jobs found!"
                emptyComponent={
                  <NoJobsComponent
                      className={"w-100"}
                      image={noJobsImg}
                      title={"No Jobs Yet? Let’s Get Started!"}
                      description={
                        "It looks like you haven’t applied for any jobs yet. Explore new opportunities and start your content creation journey with BMC."
                      }
                      btnText={"Discover Jobs"}
                      route={"/dashboard/find-jobs"}
                      showBtn2={true}
                  />
                }
                pagination
                Parent={Parent}
                // LoaderParent={LoaderParent}
                itemKey="application"
            />
          </>
        </div>
      </div>
  );
}
