import React, { useCallback } from "react";
import { returnArray } from "../utils/utils";

import videoFileIcon from "../assets/images/icons/video-file-wrapper.svg";
import refreshIcon from "../assets/images/icons/refresh-icon.svg";
import pointerIcon from "../assets/images/icons/pointer-icon.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";

export default function JobPostShopRates({
  errors,
  values,
  touched,
  setFieldValue,
  handleBlur,
  handleSeeShow,
  resetVidePrices,
  resetLivePrices,
}) {
  // Memoized renderPrices function
  const renderPrices = useCallback(
    (prices, fieldName) => {
      return prices.map((item, index) => (
        <div className="list" key={`${fieldName}-${index}`}>
          <span>
            <span className="bold">{item.count}</span>{" "}
            {item.durationCount > 1 ? `${item.duration}s` : item.duration} per{" "}
            {item.durationCount > 1 ? `${item.durationCount} months` : "month"}
          </span>
          <div className="price-wrapper">
            <div className="price">
              <span>$</span>
              <input
                type="number"
                name={`${fieldName}[${index}].price`}
                placeholder={values[fieldName][index].price ? "0" : ""}
                value={values[fieldName][index].price}
                onChange={(e) => {
                  const updatedPrices = [...values[fieldName]];
                  let newValue = e.target.value;
                  newValue = newValue.replace(/^0+/, "");

                  updatedPrices[index].price =
                    newValue === "" ? "" : Math.max(0, newValue);

                  setFieldValue(fieldName, updatedPrices);
                }}
                onBlur={handleBlur}
                className={
                  touched[fieldName]?.[index]?.price &&
                  errors[fieldName]?.[index]?.price
                    ? "is-invalid"
                    : ""
                }
              />
            </div>
            {touched[fieldName]?.[index]?.price &&
              errors[fieldName]?.[index]?.price && (
                <div className="invalid-feedback">
                  {errors[fieldName][index].price}
                </div>
              )}
            <img src={pointerIcon} alt="pointerIcon" className="pointer-icon" />
          </div>
        </div>
      ));
    },
    [values, touched, errors, setFieldValue, handleBlur]
  );

  return (
    <div className="job-post-inner-wrapper">
      <div>
        <div className="modal-text-wrapper">
          <h2>
            Here’s What We Suggest for TikTok <br /> Shop Rates
          </h2>
          <p className="medium medium-bold">
            Based on your shop’s GMV, we’ve got recommended rates for TikTok
            Shop videos <br /> and lives to help you set competitive prices.
            <button
              onClick={handleSeeShow}
              className="custom-btn blue underline semi_bold"
              type="button"
            >
              See how
            </button>
          </p>
        </div>
        <div className="shop-rates-list-main-wrapper">
          <div className="main-wrapper">
            <div className="title-wrapper">
              <div className="title">
                <img src={videoFileIcon} alt="Video Prices" />
                <strong className="medium">Video Prices</strong>
              </div>
              <img
                src={refreshIcon}
                alt="Refresh"
                onClick={() => resetVidePrices(setFieldValue)}
              />
            </div>
            <div className="main-list-wrapper">
              {renderPrices(returnArray(values.videoPrices), "videoPrices")}
            </div>
          </div>
          <div className="main-wrapper">
            <div className="title-wrapper">
              <div className="title">
                <img src={liveIcon} alt="Live Prices" />
                <strong className="medium">
                  Live Prices <span>(At least 1 hour)</span>
                </strong>
              </div>
              <img
                src={refreshIcon}
                alt="Refresh"
                onClick={() => resetLivePrices(setFieldValue)}
              />
            </div>
            <div className="main-list-wrapper">
              {renderPrices(returnArray(values.livePrices), "livePrices")}
            </div>
          </div>
        </div>
        {/* <button type="submit" className="custom-btn primary">Save Rates</button> */}
      </div>
    </div>
  );
}
