import React, { useEffect } from "react";
import settingIcon from "../assets/images/icons/settings-light-icon.svg";
import xIcon from "../assets/images/icons/x-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import NoNotificationComponent from "./NoNotificationComponent";
import moment from "moment";
import { returnArray } from "../utils/utils";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { markAllUnreadMessagesAsyncThunk } from "../redux/pagesSlices/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getInvitationsAsyncThunk } from "../redux/pagesSlices/invitationSlice";

export default function InvitationComponent({
  notifications,
  showInvitations,
  hideInvitationsHandler,
  notificationRef,
}) {
  const { loadings } = useSelector((s) => s.chat);
  const {invitations} = useSelector((s) => s.invitation);
  console.log('invitations', invitations);
  const invitationResults = (invitations?.results);
  console.log('invitationResults', invitationResults);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const today = moment().startOf("day");

  const todayNotifications = notifications?.filter((n) =>
    moment(n.lastMessageTimestamp).isSame(today, "day")
  );
  const olderNotifications = notifications?.filter((n) =>
    moment(n.lastMessageTimestamp).isBefore(today, "day")
  );

  const handleReadAll = () => {
    const notificationsToRead = returnArray(notifications).filter(
      (item) => item.unreadMessagesCount > 0
    );

    const roomIds = notificationsToRead.map((item) => item._id);
    if (!roomIds || roomIds.length === 0) return;

    dispatch(
      markAllUnreadMessagesAsyncThunk({
        roomIds,
        callback: () => {
          hideInvitationsHandler();
        },
      })
    );
  };
useEffect(() => {
  const params = {
    populate: "jobId,brandId",
  };
  {showInvitations  &&  dispatch(getInvitationsAsyncThunk(params))}
}, [showInvitations])

  

  return (
    <div
      className={`notification-main-wrapper invitations-main-wrapper ${
        showInvitations ? "show" : ""
      }`}
      ref={notificationRef}
    >
      <div className="title-wrapper">
        <strong className="dark large medium-bold">Job Invitation</strong>
      </div>
      <div className="notification-list-wrapper">
        {returnArray(notifications)?.length > -1 ? (
          <>
            <div className="notifications-wrapper">
              <div className="inner-wrapper">
                <div className="all-listings-wrapper">
                  {returnArray(todayNotifications)?.map((item, i) => (
                    <div
                      className={`notification-wrapper ${
                        item?.unreadMessagesCount > 0 ? "notifications" : ""
                      } `}
                      key={i}
                      onClick={() => navigate("/dashboard/messages")}
                    >
                      <div className="avatar-icon">
                        <a href="#">
                          <img
                            src={
                              item?.user?.avatar
                                ? parseImagePathToURL(item.user.avatar)
                                : avatarIcon
                            }
                            alt="avatarIcon"
                          />
                        </a>
                      </div>
                      <div className="text-wrapper">
                        <strong className="dark">
                          <a href="#">
                            {decodeHTML(
                              item?.lastMessage || item.room?.lastMessage
                            )}
                          </a>
                        </strong>
                        <span className="time">
                          {item?.lastMessageTimestamp
                            ? moment(item.lastMessageTimestamp).fromNow()
                            : "Just now"}
                        </span>
                      </div>
                    </div>
                  ))}

{invitationResults?.map((item, index) => (
                  <div
                    className={`notification-wrapper  `}
                    onClick={() => navigate("/dashboard/messages")}
                    key={index}
                  >
                    <div className="avatar-icon">
                      <a href="#">
                        <img src={parseImagePathToURL(item?.brandId?.avatar)} alt="avatarIcon" />
                      </a>
                    </div>
                    <div className="text-wrapper">
                      <strong className="dark light-weight">
                       {item?.brandId?.name} has invited you to a job
                        <a href="#" className="blue">
                        {item?.jobId?.jobTitle}
                        </a>
                      </strong>
                      <span className="time">{moment(item?.createdAt)?.fromNow()}</span>
                    </div>
                  </div>
                ))}
                </div>
              </div>
            </div>
            <div className="view-all-notifications-btn-wrapper">
              <button
                onClick={() => navigate("/dashboard/invitations")}
                className="view-all-notification-btn"
              >
                View all notifications
              </button>
            </div>
          </>
        ) : (
          <>
            <NoNotificationComponent />
          </>
        )}
      </div>
    </div>
  );
}