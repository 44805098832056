export const basePath = process.env.REACT_APP_BASE_URL || 'http://localhost:8000/api/v1'
export const assetsBasePath = process.env.REACT_APP_ASSET_BASE_URL || (basePath.split('/api')[0] + '/uploads/')
// export const assetsBasePath = 'https://pub-fa961811248b42dfb280445c23415b13.r2.dev/'

export const APIurls = {
    register: '/auth/register',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
    updatePassword: '/auth/update-password',
    login: '/auth/login',
    logout: '/auth/logout',
    profile: '/auth/profile',
    authenticate: '/auth/authenticate',
    refreshToken: '/auth/refresh-tokens',
    users: '/users',
    creator: '/creators',
    campaign : '/campaign',
    invitations: '/invitation',
    creatorPerformance: 'https://partnerapi.brandsmeetcreators.xyz/fetch-partners-stats',
    createJob: '/jobs',
    creatorProfile: '/creators/profile',
    brand: '/brands',
    brandProfile: '/brands/profile',
    brandAgreement: '/brands/agreement',
    payment: '/payments',
    jobs: '/jobs',
    applyJob: '/jobs/application',
    applications: '/applications',
    myJobs: '/jobs/auth',
    jobTasks: '/job-task',
    myJobsRelated: '/jobs/auth/related',
    availableJobs: '/jobs/available',
    recommendedJobs: '/jobs/recommended',
    hiredJobs: '/jobs/hired-jobs',
    contracts: '/pandadoc',
    chat: '/chat',
}
