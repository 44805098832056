import React, { useEffect, useLayoutEffect, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import unsplash from "../assets/images/unsplash.png";
import Editfill from "../assets/images/icons/Editfill.svg";
import Share from "../assets/images/icons/Share.svg";
import VideofileIcon from "../assets/images/icons/VideofileIcon.svg";
import Videofill from "../assets/images/icons/Videofill.svg";
import profileImg from "../assets/images/profileImg.png";
import MyProfileTabsComponent from "../components/MyProfileTabsComponent";
import logotiktok from "../assets/images/icons/logotiktok.svg";
import logofacebook from "../assets/images/icons/logofacebook.svg";
import logoinstagram from "../assets/images/icons/logoinstagram.svg";
import logoyt from "../assets/images/icons/logoyt.svg";
import PlayIcon from "../assets/images/icons/play-icon.svg";
import analytics from "../assets/images/icons/analytics.svg";
import dollarIcon from "../assets/images/icons/dollarIcon.svg";
import { FaPlay } from "react-icons/fa";
import videoMediaImg from "../assets/images/video-media-img.svg";
import { useDispatch, useSelector } from "react-redux";
import { authenticateAsyncThunk } from "../redux/pagesSlices/authSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { dateFormat } from "../utils/format";
import { calculateAge } from "../utils/globalFunctions";
import moment from "moment";
import { Link } from "react-router-dom";
import { getId, returnArray } from "../utils/utils";
import DashboardTrendsChartComponent from "../components/DashboardTrendsChartComponent";
import TrendLineChart from "../components/TrendLineChart";
import arrowInBoxIcon from "../assets/images/icons/arrow-in-box.svg";

import facebookColored from "../assets/images/icons/facebook-colored-icon.svg.svg";
import xColored from "../assets/images/icons/x-colored-icon.svg.svg";
import whatsAppColored from "../assets/images/icons/whatsApp-colored-icon.svg";
import linkDinColored from "../assets/images/icons/linkdin-colored-icon.svg";
import copyColored from "../assets/images/icons/copy-circular-iocn.svg";
import { ImShare2 } from "react-icons/im";
import EditProfileModal from "../components/modals/EditProfileModal";
import { copyCurrentUrlToClipboard } from "../utils/globalFunctions";
import { getGMV, getGMVPerVideo, getRoi, newGetGMV, newGetGMVPerVideo } from "../utils/getStats";
import {
  getCreatorProfileAnalyticsAsyncThunk,
  getCreatorVideosAsyncThunk,
} from "../redux/pagesSlices/creatorSlice";
import { FaPause } from "react-icons/fa6";

const displayFields = {
  location: "Location",
  age: "Age",
  gender: "Gender",
  createdAt: "Member Since",
};

const socialMediaIcons = {
  TikTok: logotiktok,
  Instagram: logoinstagram,
  YouTube: logoyt,
  Facebook: logofacebook,
};

const analyticsFields = {
  GMV: logotiktok,
  Instagram: logoinstagram,
  YouTube: logoyt,
  Facebook: logofacebook,
};

const projects = [
  { id: 1, video: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { id: 2, video: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { id: 3, video: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { id: 4, video: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { id: 5, video: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { id: 6, video: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { id: 7, video: "https://www.w3schools.com/html/mov_bbb.mp4" },
  { id: 8, video: "https://www.w3schools.com/html/mov_bbb.mp4" },
];

const MyProfile = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const { profile, brand, creator, user } = useSelector((s) => s.auth);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [editModalModal, setEditModalModal] = useState(false);
  const d = useDispatch();
  const [trendActiveState, setTrendActiveState] = useState("gmv");
  const creatorProfile = creator?.json?.creator_profile;

  console.log("profile", profile);
  console.log("brand", brand);
  console.log("creator_profile", creator);
  console.log("user", user);

  // useLayoutEffect(() => {
  //   d(authenticateAsyncThunk());
  // }, []);

  // Move `getButtonClass` inside `MyProfile` component
  const getButtonClass = (tabName) => {
    setActiveTab(tabName);
  };

  const handlePlay = (index) => {
    setPlayingIndex(index); // Set the currently playing video's index
    const videoElement = document.getElementById(`video-${index}`);
    if (videoElement) {
      videoElement.play(); // Play the video programmatically
    }
  };
  const trendActiveStateHandler = (tab) => {
    setTrendActiveState(tab);
  };
  const editModalShowHandler = () => {
    setEditModalModal(true);
  };
  const editModalCloseHandler = () => {
    setEditModalModal(false);
  };
  useEffect(() => {
    const params = {
      profileType: 2,
    };
    d(
      getCreatorProfileAnalyticsAsyncThunk({
        id: getId(creator),
        params,
      })
    );
  }, [d]);
  const creatorStats = useSelector(
    (s) => s.creator?.creatorStats?.data?.creator_profile
  );
  useEffect(() => {
    const params = {
      profileType: 5,
    };
    d(
      getCreatorVideosAsyncThunk({
        id: getId(creator),
        params,
      })
    );
  }, [d, activeTab === "tab2"]);
  const creatorVideos = useSelector(
    (s) => s.creator?.creatorVideos?.data?.creator_profile?.top_video_data
  );

  const videosArray = returnArray(creatorVideos?.value);
  console.log("videosArray", videosArray);

  const genderPercentages = creatorStats?.follower_genders_v2?.value?.map(
    (gender) => ({
      key: gender.key,
      percentage: Math.round(parseFloat(gender.value) * 100), // Convert to percentage and round
    })
  );

  // Extract percentages
  const malePercentage =
    genderPercentages?.find((g) => g.key === "male")?.percentage || 0;
  const femalePercentage =
    genderPercentages?.find((g) => g.key === "female")?.percentage || 0;

  const handlePlayPause = (index) => {
    const videoElement = document.getElementById(`video-${index}`);

    if (playingIndex === index) {
      // Pause the video if it's already playing
      videoElement.pause();
      setPlayingIndex(null);
    } else {
      // Play the selected video and pause others
      videosArray.forEach((_, i) => {
        const otherVideo = document.getElementById(`video-${i}`);
        if (i !== index && otherVideo) otherVideo.pause();
      });
      videoElement.play();
      setPlayingIndex(index);
    }
  };

  return (
    <div className="my-profile-page" style={{ position: "relative" }}>
      <div className="top-image" style={{ position: "relative" }}>
        <div className="buttons-div">
          <button className="btn1" onClick={editModalShowHandler}>
            <img src={Editfill} alt="Edit-fill" />
            <span> Edit profile</span>
          </button>
          <EditProfileModal
            editModalCloseHandler={editModalCloseHandler}
            editModalModal={editModalModal}
          />
          <DropdownButton
            id={`dropdown-button-drop-down`}
            drop={"down"}
            variant="secondary"
            className="custom-dropdown"
            title={
              <>
                <img src={Share} alt="Share" /> Share profile
              </>
            }
          >
            <div className="title">
              <strong className="dark">Share profile</strong>
            </div>
            <Dropdown.Item
              onClick={copyCurrentUrlToClipboard}
              eventKey="1"
              className="dark"
              as={Link}
              to="/dashboard/my-profile"
            >
              <img src={copyColored} alt="userLightIcon" className="nofilter" />{" "}
              Copy link
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              className="dark"
              as={Link}
              to="/dashboard/settings"
            >
              <img
                src={facebookColored}
                alt="userLightIcon"
                className="nofilter"
              />{" "}
              Facebook
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              className="dark"
              as={Link}
              to="/dashboard/settings"
            >
              <img src={xColored} alt="userLightIcon" className="nofilter" /> X
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="4"
              className="dark"
              as={Link}
              to="/dashboard/settings"
            >
              <img
                src={whatsAppColored}
                alt="userLightIcon"
                className="nofilter"
              />{" "}
              WhatsApp{" "}
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="5"
              className="dark"
              as={Link}
              to="/dashboard/settings"
            >
              <img
                src={linkDinColored}
                alt="linkDinColored"
                className="nofilter"
              />{" "}
              LinkedIn
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>

      <div className="profile-card-tabs-ui-parent">
        <div className="container large">
          <div className="row">
            <div className="col-md-3 margin-top-80">
              <div className="card profile-card">
                <div className="profile-img-section text-center">
                  <div className="img-div">
                    {profile?.avatar ? (
                      <img
                        src={parseImagePathToURL(profile?.avatar) || profileImg}
                        alt="profile-img"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={profileImg}
                        alt="profile-img"
                        className="img-fluid"
                      />
                    )}
                  </div>
                  <span>
                    {creator?.firstName} {creator?.lastName}
                  </span>
                </div>
                <div className="status">
                  <div className="status-comp1">
                    <span className="heading">
                      {/* {
                        creator.json?.creator_profile?.med_gmv_revenue_range
                          ?.value
                      } */}
                      {newGetGMVPerVideo(creatorStats?.med_gmv_revenue?.value?.value, creatorStats?.med_gmv_revenue_range?.value, creatorStats?.video_publish_cnt_30d?.value) || "$0"}
                    </span>
                    <span className="text">GMV Per Video</span>
                  </div>
                  <div className="hr"></div>
                  <div className="status-comp1">
                    <span className="heading">
                      {/* {
                        creator?.json?.creator_profile?.med_gmv_revenue?.value
                          ?.value
                      } */}
                      {/* {creatorStats?.med_gmv_revenue?.value?.format || "$0"} */}
                      {
                        newGetGMV(creatorStats?.med_gmv_revenue?.value?.value, creatorStats?.med_gmv_revenue_range?.value) || 0
                      }
                    </span>
                    <span className="text">GMV</span>
                  </div>
                </div>

                <div className="info">
                  <table className="user-info-table">
                    <tbody>
                      {Object.entries(displayFields)?.map(
                        ([key, label], index) => (
                          <tr key={index}>
                            <td>{label}</td>
                            <td className="user-info-value">
                              {key === "age"
                                ? calculateAge(profile.dob)
                                : key === "createdAt"
                                ? dateFormat(profile[key])
                                : profile[key] || "N/A"}{" "}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="social-icons">
                  {profile?.socials?.map((social, index) => {
                    const { type, url } = social;
                    const icon = socialMediaIcons[type]; // Get the corresponding icon
                    return (
                      icon && (
                        <div className="img-parent" key={index}>
                          <Link
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={icon} alt={`${type}-logo`} />
                          </Link>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="bottom-ui-tabs">
                <MyProfileTabsComponent
                  activeTab={activeTab}
                  getButtonClass={getButtonClass}
                />

                {activeTab === "tab1" && (
                  <div className="tab-ui">
                    <div className="sec1">
                      <span>What’s Unique About Me</span>
                      <p>{profile?.bio}</p>
                    </div>
                    <div className="sec2">
                      <div className="sec2-comp1">
                        <span>Content type</span>
                        <div className="sec2-comp1-btns">
                          {profile?.videoPrices?.length > 0 && (
                            <button>
                              <img src={VideofileIcon} alt="VideofileIcon" />
                              <span>Video</span>
                            </button>
                          )}
                          {profile?.livePrices?.length > 0 && (
                            <button style={{ background: "#F7EFF5" }}>
                              <img src={Videofill} alt="Videofill" />
                              <span>Live</span>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="sec2-comp2">
                        <span>Categories</span>
                        <div
                          className="sec2-comp2-btns"
                          style={{ gap: "5.85px" }}
                        >
                          {profile?.categories?.map((category, index) => (
                            <button key={index}>{category}</button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div className="project-section">
                    <h4 className="section-title">
                      Some projects I've worked on
                    </h4>
                    <div className="row">
                      <ul className="videos-media-wrapper">
                        {videosArray?.map((url, index) => (
                          <li key={index}>
                            <video
                              id={`video-${index}`}
                              controls={false} // Hide default controls since we're adding custom controls
                              width="600"
                              poster={videoMediaImg}
                              onPause={() => setPlayingIndex(null)} // Reset state when video pauses
                              onPlay={() => setPlayingIndex(index)} // Update state when video plays
                            >
                              <source
                                src={parseImagePathToURL(
                                  url?.video?.video_infos[0]?.main_url
                                )}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                            <div
                              className="icon-wrapper"
                              onClick={() => handlePlayPause(index)} // Toggle play/pause on click
                              style={{ cursor: "pointer" }} // Add pointer cursor for clarity
                            >
                              {playingIndex === index ? (
                                <FaPause />
                              ) : (
                                <FaPlay />
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                      {profile?.sample_videos?.map((url, index) => (
                        <div className="col-md-3 col-6 mb-3" key={index}>
                          <div
                            className={`project-card ${
                              playingIndex === index ? "playing" : ""
                            }`}
                          >
                            <div
                              className={`project-video-wrapper ${
                                playingIndex === index ? "playing-video" : ""
                              }`}
                            >
                              <video
                                id={`video-${index}`}
                                controls={playingIndex === index ? true : false}
                                width="600"
                                poster={videoMediaImg}
                                onPause={() => setPlayingIndex(null)} // Reset state when video pauses
                              >
                                <source
                                  src={parseImagePathToURL(url)}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                              {playingIndex !== index && (
                                <div
                                  className="icon-wrapper"
                                  onClick={() => handlePlay(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FaPlay />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {activeTab === "tab3" && (
                  <div className="analyticANDinsight-section">
                    <h4 className="section-title">Analytics </h4>
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="project-card">
                          <div className="icon-wrapper">
                            <img src={analytics} alt="analytics" />
                          </div>
                          <div className="context-sec">
                            <span className="text1">GMV</span>
                            <span className="text2">
                              {creatorStats?.med_gmv_revenue?.value?.format ||
                                "$0"}
                            </span>
                            <span className="text3">
                              {creatorStats?.med_gmv_revenue_range?.value ||
                                "0-0"}{" "}
                              Per Buyer
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="project-card">
                          <div className="icon-wrapper">
                            <img src={analytics} alt="analytics" />
                          </div>
                          <div className="context-sec">
                            <span className="text1">Video GPM</span>
                            <span className="text2">
                              {creatorStats?.ec_video_gpm?.value
                                ?.maximum_format || "$0"}
                            </span>
                            <span className="text3">
                              {creatorStats?.ec_video_gpm?.value
                                ?.minimal_format || "$0"}{" "}
                              Min GPM
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="project-card">
                          <div className="icon-wrapper">
                            <img src={analytics} alt="analytics" />
                          </div>
                          <div className="context-sec">
                            <span className="text1">Items Sold</span>
                            <span className="text2">
                              {creatorStats?.units_sold?.value || "$0"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="project-card">
                          <div className="icon-wrapper">
                            <img src={analytics} alt="analytics" />
                          </div>
                          <div className="context-sec">
                            <span className="text1">Avg. Live Viewers</span>
                            <span className="text2">
                              {creatorStats?.live_med_view_cnt?.value || "0"}
                            </span>
                            <span className="text3">
                              {creatorStats?.live_streaming_cnt_30d?.value ||
                                "0"}
                              Streams in 30 Days
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="project-card">
                          <div className="icon-wrapper">
                            <img src={analytics} alt="analytics" />
                          </div>
                          <div className="context-sec">
                            <span className="text1">Avg. Video Views</span>
                            <span className="text2">
                              {creatorStats?.video_med_view_cnt?.value || "0"}
                            </span>
                            <span className="text3">
                              {creatorStats?.video_publish_cnt_30d?.value ||
                                "0"}{" "}
                              Videos in 30 Days
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="project-card">
                          <div className="icon-wrapper">
                            <img src={analytics} alt="analytics" />
                          </div>
                          <div className="context-sec">
                            <span className="text1">Video GPM</span>
                            <span className="text2">
                              {creatorStats?.ec_video_gpm?.value
                                ?.maximum_format || "$0"}
                            </span>
                            <span className="text3">
                              male {malePercentage}% female {femalePercentage}%
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="project-card">
                          <div className="icon-wrapper">
                            <img src={analytics} alt="analytics" />
                          </div>
                          <div className="context-sec">
                            <span className="text1">Live GPM</span>
                            <span className="text2">
                              {creatorStats?.ec_live_gpm?.value
                                ?.maximum_format || "$0"}
                            </span>
                            <span className="text3">
                              {creatorStats?.ec_live_gpm?.value
                                ?.minimal_format || "$0"}
                              Min GPM
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 mb-4">
                        <div className="project-card">
                          <div className="icon-wrapper">
                            <img src={analytics} alt="analytics" />
                          </div>
                          <div className="context-sec">
                            <span className="text1">My ROI</span>
                            <span className="text2">${getRoi(creatorStats?.med_gmv_revenue?.value?.value, creatorStats?.med_gmv_revenue_range?.value) || 0}</span>
                            <span className="text3">_</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trend-chart-main-wrapper mt-4">
                      <div className="job-info-wrapper">
                        <div className="title-wrapper">
                          <strong className="large dark">Trends</strong>
                          <div className="info-icon-wrapper">
                            <a href="#">
                              <img src={arrowInBoxIcon} alt="arrowInBoxIcon" />
                            </a>
                          </div>
                        </div>
                        <DashboardTrendsChartComponent
                          trendActiveStateHandler={trendActiveStateHandler}
                          trendActiveState={trendActiveState}
                        />
                        {trendActiveState === "gmv" && <TrendLineChart />}
                        {trendActiveState === "itemSold" && <TrendLineChart />}
                        {trendActiveState === "videoGpm" && <TrendLineChart />}
                        {trendActiveState === "liveViews" && <TrendLineChart />}
                        {trendActiveState === "videoViews" && (
                          <TrendLineChart />
                        )}
                        {trendActiveState === "liveGpm" && <TrendLineChart />}
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "tab4" && (
                  <div className="services-section">
                    <div className="services-sec-comp1">
                      <h4 className="section-title">Videos</h4>
                      <div className="row g-3">
                        {returnArray(creator?.videoPrices)?.map((price) => (
                          <div
                            className="col-lg-3 col-md-3 col-sm-6 mb-3"
                            key={price.id}
                          >
                            <div className="project-card">
                              <div className="icon-wrapper">
                                <img src={dollarIcon} alt="analytics" />
                              </div>
                              <div className="context-sec">
                                <span className="text1">
                                  {price.count} Videos per month
                                </span>
                                <span className="text2">${price.price}</span>
                                {/* <span className="text3">
                                  {service.minGPM} Min GPM
                                </span> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="services-sec-comp2">
                      <h4 className="section-title">Live</h4>
                      <div className="row g-3">
                        {returnArray(creator?.livePrices)?.map((price) => (
                          <div
                            className="col-lg-3 col-md-3 col-sm-6 mb-3"
                            key={price.id}
                          >
                            <div className="project-card">
                              <div className="icon-wrapper">
                                <img src={dollarIcon} alt="dollarIcon" />
                              </div>
                              <div className="context-sec">
                                <span className="text1">
                                  {price?.count} Lives per month
                                </span>
                                <span className="text2">${price?.price}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
