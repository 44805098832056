import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import Dropdown from "react-bootstrap/Dropdown";
import smackin from "../assets/images/smackin-job.png";
import trainer from "../assets/images/trainer-job.png";
import timmer from "../assets/images/icons/Tumer_fill.png";
import { getInvitationsAsyncThunk } from "../redux/pagesSlices/invitationSlice";
import { useDispatch, useSelector } from "react-redux";
import { getId, returnArray } from "../utils/utils";
import { parseImagePathToURL } from "../utils/globalFunctions";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import NoDataComponent from "../components/NoDataComponent";
import { useNavigate } from "react-router-dom";
import NoJobsComponent from "../components/NoJobsComponent";
const JobOfferPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      populate: "jobId,brandId",
    };
    dispatch(getInvitationsAsyncThunk(params));
  }, [dispatch]);
  const invitations = useSelector((s) => s.invitation?.invitations);
  const invitationsArray = returnArray(invitations?.results);
  const loading = useSelector(
    (s) => s.invitation.loadings.getInvitationsAsyncThunk
  );

  const [messageClicked, setMessageClicked] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  const handleMessageClicked = (index) => {
    // Set the clicked index or reset if clicking the same index again
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="container job-offer-page">
      <div className="job-head mb-3">
        <h2 className="med-bold">My Job Offers</h2>

        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            TODAY
            <IoIosArrowDown />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div>
        {/* Show Loading Indicator */}
        {loading && <Spinner />}

        {/* Show No Data Component */}
        {!loading && (!invitationsArray || invitationsArray.length === 0) && (
          // <NoJobsComponent />
          <>
          </>
        )}

        {/* Show Invitations Listing */}
        {!loading &&
          invitationsArray?.length > 0 &&
          invitationsArray.map((item, index) => (
            <div className="job-head inbox-chat" key={index}>
              <div className="chat-profile">
                <img
                  src={parseImagePathToURL(item?.jobId?.image?.[0]) || smackin}
                  alt="img"
                />
              </div>
              <div className="main-chat">
                <div className="inner-chat">
                  <p className="dark_text medium-bold m-0">
                    You've Got a Job Offer!
                  </p>
                </div>
                <p
                  className="dark_text medium-bold m-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleMessageClicked(index)}
                >
                  {item?.brandId?.name} has invited you to collaborate on a new
                  project. Check the details and respond to the offer.
                </p>
                <span className="small">
                  {moment(item?.createdAt)?.fromNow()}
                </span>
                {item?.invitationStatus === "accepted" ? (
                  <div className="job-head job-invite">
                    <button onClick={() => navigate(`/dashboard/job/${getId(item?.jobId)}`)} className="custom-btn bg-blue invite">
                      View Details
                    </button>
                  </div>
                ):(
<div
                  className={`${
                    activeIndex !== index ? "d-none" : ""
                  } job-head job-invite`}
                >
                  <button
                    onClick={() =>
                      navigate(
                        `/dashboard/job/${getId(item?.jobId)}`
                      )
                    }
                    className="custom-btn bg-blue invite"
                  >
                    View invite
                  </button>
                  <button className="custom-btn reject">Reject</button>
                </div>
                )}
                
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default JobOfferPage;
