import moment from "moment";
import { assetsBasePath } from "../constants";
import { toast } from "react-toastify";


// Utilities for Chat App ====================================================
// export const parseImagePathToURL = (path) => `${assetsBasePath}${path}`
export const parseImagePathToURL = (path) => {
  if (path?.startsWith('http://') || path?.startsWith('https://')) {
    return path;
  }
  return `${assetsBasePath}${path}`;
}

// image checking utility for chat 
export function isImageURL(url) {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
  const extension = url.split('.').pop().split(/\#|\?/)[0].toLowerCase();
  return imageExtensions.includes(extension);
}

export const extractFileName = (url) => {
  const parts = url.split('/');
  // const fullFileName = parts[parts.length - 1];
  const fileNameParts = url.split('-');
  return fileNameParts.slice(1).join('-');  
};

export const isAudioFile = (file) => {
  const audioExtensions = ['webm', 'wav', 'mp3', 'ogg'];
  const fileExtension = file.split('.').pop();
  return audioExtensions.includes(fileExtension);
};

export function generateObjectId() {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16); // 4-byte timestamp

  // Use crypto to generate more random 8 bytes (16 hex characters)
  const randomBytes = [...crypto.getRandomValues(new Uint8Array(8))]
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');

  return timestamp + randomBytes;
}

export function downloadFile(url , fileName) {
  const link = document.createElement('a');
  link.href = parseImagePathToURL(url);
  link.download =  fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function calculateAge(dob) {
  return moment().diff(moment(dob), "years"); // Calculate the difference in years
}

// copy url address on click of copy button

export const copyCurrentUrlToClipboard = () => {
  // Get the current URL from the browser's address bar
  const currentUrl = window.location.href;

  // Use the Clipboard API to copy the URL
  navigator.clipboard.writeText(currentUrl)
    .then(() => {
      // Optional: Display a success message
      toast.success("URL copied to clipboard!");
    })
    .catch((error) => {
      // Handle errors if any
      console.error("Failed to copy the URL:", error);
      toast.error("Failed to copy the URL");
    });
};


// Utilities for Chat App ====================================================


// export const parseImagePathToURL = (path) => {
//     // Encode the path to handle spaces and special characters
//     const encodedPath = encodeURIComponent(path);
//     return `${assetsBasePath}${encodedPath}`;
//   };


export const pick = (object, keys) => {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = object[key];
    }
    return obj;
  }, {});
};


export const removeId = (obj) => {
  if (Array.isArray(obj)) {
    // If it's an array, apply the function to each element
    return obj.map(item => removeId(item));
  } else if (obj !== null && typeof obj === 'object') {
    // If it's an object, create a new object excluding the `_id` key
    return Object.keys(obj).reduce((acc, key) => {
      if (key !== '_id') {
        acc[key] = removeId(obj[key]); // Recur for nested objects/arrays
      }
      return acc;
    }, {});
  } else {
    // If it's not an object or array, return it as is
    return obj;
  }
}
