import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";

import ChoosePlatform from "./pages/ChoosePlatform";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import FindJobsPage from "./pages/FindJobsPage";
import DashboardHome from "./pages/DashboardHome";
import DashboardHomeNoData from "./pages/DashboardHomeNoData";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticateAsyncThunk,
  userLogoutAsyncThunk,
} from "./redux/pagesSlices/authSlice";
import Authenticated from "./layouts/authenticated";
import UnAuthenticated from "./layouts/unAuthenticated";
import MyJobsPage from "./pages/MyJobsPage";
import MyProposals from "./pages/MyProposals";
import TrackProposal from "./pages/TrackProposal";
import ProposalDetailPage from "./pages/ProposalDetailPage";
import JobDetailPage from "./pages/JobDetailPage";
import TrackProjectPage from "./pages/TrackProjectPage";
import MessagesPage from "./pages/MessagesPage";
import LoaderComponent from "./components/LoaderComponent";
import MyProfile from "./pages/MyProfile";
import Settings from "./pages/Settings";
import ForgetPassword from "./pages/ForgetPassword";
import EmailSentTemplate from "./pages/EmailSentTemplate";
import EnterNewPassword from "./pages/EnterNewPassword";
import SuccessChangedPasswoedTemplate from "./pages/SuccessChangedPasswoedTemplate";
import { Button } from "react-bootstrap";
import { TourProvider } from "@reactour/tour";
import { tourSteps } from "./utils/tourSteps";
import ReauthPage from "./pages/ReAuthPage";
import GoogleAuthCallback from "./pages/GoogleAuthCallback";
import "@reactour/popover/dist/index.css";
import JobOfferPage from "./pages/JobOfferPage";

function App() {
  const d = useDispatch();
  const auth = useSelector((s) => s.auth);
  const loading = auth?.loadings?.authenticateAsyncThunk;
  // const user = auth?.user;

  useEffect(() => {
    d(authenticateAsyncThunk());
  }, [d]);

  if (loading) return <LoaderComponent />;
  const popoverPadding = 0;
  const opositeSide = {
    top: "bottom",
    bottom: "top",
    right: "left",
    left: "right",
  };

  function doArrow(position, verticalAlign, horizontalAlign) {
    if (!position || position === "custom") {
      return {};
    }

    const width = 16;
    const height = 12;
    const color = "white";
    const isVertical = position === "top" || position === "bottom";
    const spaceFromSide = 10;

    const obj = {
      [`--rtp-arrow-${
        isVertical ? opositeSide[horizontalAlign] : verticalAlign
      }`]: height + spaceFromSide + "px",
      [`--rtp-arrow-${opositeSide[position]}`]: -height + 2 + "px",
      [`--rtp-arrow-border-${isVertical ? "left" : "top"}`]: `${
        width / 2
      }px solid transparent`,
      [`--rtp-arrow-border-${isVertical ? "right" : "bottom"}`]: `${
        width / 2
      }px solid transparent`,
      [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
    };
    return obj;
  }

  return (
    <div className="App">
      <TourProvider
        steps={tourSteps}
        showBadge={false}
        padding={{ popover: popoverPadding + 5 }}
        styles={{
          maskWrapper: (base) => ({
            ...base,
            color: "transparent",
          }),
          popover: (base, state) => {
            return {
              ...base,
              borderRadius: 10,
              ...doArrow(
                state.position,
                state.verticalAlign,
                state.horizontalAlign
              ),
            };
          },
        }}
        prevButton={({ currentStep, setCurrentStep, steps }) => {
          const first = currentStep === 0;
          return (
            <button
              className="btn-style text"
              onClick={() => {
                if (first) {
                  setCurrentStep((s) => steps.length - 1);
                } else {
                  setCurrentStep((s) => s - 1);
                }
              }}
            >
              Back
            </button>
          );
        }}
        nextButton={({
          Button,
          currentStep,
          stepsLength,
          setIsOpen,
          setCurrentStep,
          steps,
        }) => {
          const last = currentStep === stepsLength - 1;
          return (
            <Button
              className="btn-style"
              onClick={() => {
                if (last) {
                  setIsOpen(false);
                } else {
                  setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
                }
              }}
            >
              {last ? (
                <button className="btn-style">Finish!</button>
              ) : (
                <button className="btn-style"> Next</button>
              )}
            </Button>
          );
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<UnAuthenticated Component={ChoosePlatform} />}
            />
            <Route
              path="/sign-up"
              element={<UnAuthenticated Component={SignUp} />}
            />
            <Route
              path="/sign-in"
              element={<UnAuthenticated Component={SignIn} />}
            />
            <Route
              path="/forget-password"
              element={<UnAuthenticated Component={ForgetPassword} />}
            />
            <Route
              path="/email-sent"
              element={<UnAuthenticated Component={EmailSentTemplate} />}
            />
            <Route
              path="/callback"
              element={<UnAuthenticated Component={GoogleAuthCallback} />}
            />
            <Route
              path="/new-password"
              element={<UnAuthenticated Component={EnterNewPassword} />}
            />
            <Route
              path="/password-changed"
              element={
                <UnAuthenticated Component={SuccessChangedPasswoedTemplate} />
              }
            />
            <Route
              path="/restricted"
              element={<Authenticated Component={Restricted} />}
            />
            <Route
              path="/dashboard"
              element={<Authenticated Component={Dashboard} />}
            >
              <Route
                index
                path=""
                element={<Authenticated Component={DashboardHomeNoData} />}
              />
              <Route
                path="home"
                element={<Authenticated Component={DashboardHome} />}
              />
              <Route
                path="find-jobs"
                element={<Authenticated Component={FindJobsPage} />}
              />
              <Route
                path="my-jobs"
                element={<Authenticated Component={MyJobsPage} />}
              />
              <Route
                path="my-proposals"
                element={<Authenticated Component={MyProposals} />}
              />
              <Route
                path="proposals/:id"
                element={<Authenticated Component={ProposalDetailPage} />}
              />
              <Route
                path="proposals/:id/track"
                element={<Authenticated Component={TrackProposal} />}
              />
              <Route
                path="job/:id"
                element={<Authenticated Component={JobDetailPage} />}
              />
              <Route
                path="messages"
                element={<Authenticated Component={MessagesPage} />}
              />
              <Route
                path="track-project"
                element={<Authenticated Component={TrackProjectPage} />}
              />
              <Route
                path="my-profile"
                element={<Authenticated Component={MyProfile} />}
              />
              <Route
                path="settings"
                element={<Authenticated Component={Settings} />}
              />
              <Route
                path="invitations"
                element={<Authenticated Component={JobOfferPage} />}
              />
            </Route>
            <Route
              path="/reauth"
              element={<Authenticated Component={ReauthPage} />}
            />
              {/* <Route
              path="/job-offfer"
              element={<JobOfferPage />}
            /> */}
          </Routes>
          
        </BrowserRouter>
      </TourProvider>
    </div>
  );
}

export default App;

const Restricted = () => {
  const auth = useSelector((s) => s.auth);
  const user = auth?.user;
  const d = useDispatch();
  if (user.role === "creator") {
    return <Navigate to={"/dashboard"} />;
  }
  const handleLogout = () => {
    d(userLogoutAsyncThunk());
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <h1 style={{ color: "black" }}>Restricted</h1>
      <p>You are Brand and You unable to access this portal</p>
      <a
        href={process.env.REACT_APP_BRAND_BASE_URL}
        className="btn btn-warning"
      >
        Go to Brand Portal
      </a>
      <Button variant="danger" onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );
};
