import React, { useState } from "react";
import { Link } from "react-router-dom";
import JobsImg from "../assets/images/job-img-1.svg";
import { parseImagePathToURL } from "../utils/globalFunctions";
import smsIcon from "../assets/images/icons/sms-icon.svg";
import { getId, returnArray } from "../utils/utils";
export default function MyJob({ application }) {
  let isActiveStepReached = false; // Tracks if an active step has been reached

  const isContentCreated =
    (application?.videoPrice?.count &&
      application?.livePrice?.count &&
      application?.jobProgressInfo?.video_count >=
        application?.videoPrice?.count &&
      application?.jobProgressInfo?.live_count >=
        application?.livePrice?.count) ||
    (application?.videoPrice?.count &&
      !application?.livePrice?.count &&
      application?.jobProgressInfo?.video_count >=
        application?.videoPrice?.count) ||
    (application?.livePrice?.count &&
      !application?.videoPrice?.count &&
      application?.jobProgressInfo?.live_count >=
        application?.livePrice?.count);

  console.log("application?.videoPrice?.count", application?.videoPrice?.count);
  const getClassForStep = (isComplete, isActive) => {
    console.log("isActiveStepReached", isActiveStepReached);
    if (isActiveStepReached) return ""; // Return empty if an active step has already been reached
    if (isComplete) return "complete";
    if (isActive) {
      isActiveStepReached = true; // Mark this step as active and block further classes
      return "active";
    }
    return "";
  };
  return (
    <tr>
      <td>
        <div className="main-wrapper">
          <div className="img-wrapper">
            <Link to={`/dashboard/track-project?jobId=${getId(application)}`}>
              <img
                src={
                  returnArray(application?.jobId?.image)?.[0]
                    ? parseImagePathToURL(application?.jobId?.image[0])
                    : JobsImg
                }
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <p className="dark">
              <Link to={`/dashboard/track-project?jobId=${getId(application)}`}>
                {application?.jobId?.jobTitle}
              </Link>
            </p>
          </div>
        </div>
      </td>
      <td>
        <div className="main-status-wrapper">
          <ul className="status-list">
            <li
              className={getClassForStep(
                application?.sampleStatus === "requested" ||
                  application?.sampleStatus === "received",
                !(
                  application?.sampleStatus === "requested" ||
                  application?.sampleStatus === "received"
                )
              )}
            >
              <span className="status"></span> Request sample{" "}
            </li>
            <li
              className={getClassForStep(
                application?.sampleStatus === "received",
                application?.sampleStatus === "requested"
              )}
            >
              <span className="status"></span> Confirm sample{" "}
            </li>

            <li
              className={getClassForStep(isContentCreated, !isContentCreated)}
            >
              <span className="status"></span> Create content
            </li>

            <li
              className={getClassForStep(
                application?.brandApproval,
                !application?.brandApproval
              )}
            >
              <span className="status"></span>Brand review & Completion{" "}
            </li>
            <li
              className={getClassForStep(
                application?.creatorPaymentConfirmed,
                !application?.creatorPaymentConfirmed
              )}
            >
              <span className="status"></span> Payout
            </li>
          </ul>
          <div className="btns-wrapper">
            <Link
              to={`/dashboard/messages?id=${getId(
                application?.brandId
              )}&jobId=${getId(application?.jobId)}`}
              className="btn-style light"
            >
              {" "}
              <img src={smsIcon} alt="smsIcon" /> Message Brand
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
}
