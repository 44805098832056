import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { MdOutlineAttachment } from "react-icons/md";
import LinkTrackingUrlModal from "./LinkTrackingUrlModal";
import { FaEnvelope } from "react-icons/fa6";
import confirmPayment from "../assets/images/icons/subtract-icon.svg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import videofile from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import { TbRefresh } from "react-icons/tb";
import { HiMiniInformationCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationWithStatsAsyncThunk,
  receiveApplicationPaymentAsyncThunk,
  receiveJobSampleAsyncThunk,
  requestApplicationSampleAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import { Spinner } from "react-bootstrap";
import { sampleRecordList } from "../utils/constant";
import { titleCase } from "../utils/format";
import { renewJobAsyncThunk } from "../redux/pagesSlices/jobSlice";
import { getId } from "../utils/utils";
import { FaCreditCard } from "react-icons/fa";
import { updateJobTaskWithIdAsyncThunk } from "../redux/pagesSlices/jobTaskSlice";
import { toast } from "react-toastify";

export default function TrackJobsStepsContent({ proposalDetails }) {
  const [editModalState, setEditModalState] = useState(false);

  const { loadings } = useSelector((s) => s.job);
  const isLoading = useSelector(
    (s) => s.application?.loadings?.receiveApplicationPaymentAsyncThunk
  );

  const sampleRecievedLoading = useSelector(
    (s) => s.application?.loadings?.receiveJobSampleAsyncThunk
  );
  console.log("Is loading data", isLoading);

  const editModalOpenHandler = () => {
    setEditModalState(true);
  };
  const editModalCloseHandler = () => {
    setEditModalState(false);
  };

  console.log("Proposal Details", proposalDetails);

  const dispatch = useDispatch();

  const handleReceievedSamples = async () => {
    await dispatch(
      receiveJobSampleAsyncThunk({
        id: getId(proposalDetails),
        callBack: () => {
          dispatch(getApplicationWithStatsAsyncThunk(getId(proposalDetails)));
        },
      })
    );
  };

  const handleRenewJob = async () => {
    await dispatch(
      renewJobAsyncThunk({
        id: getId(proposalDetails),
        callBack: () => {
          dispatch(getApplicationWithStatsAsyncThunk(getId(proposalDetails)));
        },
      })
    );
  };

  const handleUpdateJobTask = () => {
    const data = {
      creatorPaymentConfirmed: true,
      status: "completed",
    };
    dispatch(
      updateJobTaskWithIdAsyncThunk({
        id: getId(proposalDetails),
        data,
        callback: () => {
          toast.success("Payment confirmed successfully!");
          dispatch(getApplicationWithStatsAsyncThunk(getId(proposalDetails)));
        },
      })
    );
  };

  const accordionData = [
    {
      eventKey: "0",
      title: "Request sample",
      description: "Request the brands sample",
      action: (
        <button
          className="custon-btn light"
          onClick={() =>
            window.open(
              proposalDetails?.jobId?.product_link ||
                proposalDetails?.jobId?.product_id
            )
          }
        >
          <FaCreditCard /> Request sample
        </button>
      ),
    },
    {
      eventKey: "1",
      title: "Confirm sample",
      description: "Confirm sample was received",
      action: (
        <div className="attatch-link-wrapper">
          <button
            className="custon-btn light"
            disabled={sampleRecievedLoading}
            onClick={handleReceievedSamples}
          >
            Yes, I received it
          </button>
          <Link
            to={`/dashboard/messages?id=${proposalDetails?.brandId?.id}&jobId=${
              proposalDetails?.id || proposalDetails?._id
            }`}
            className="custon-btn light ms-3"
          >
            No, I am having trouble with my sample
          </Link>
        </div>
      ),
    },

    {
      eventKey: "2",
      title: "Create content",
      description:
        "Once all content is submitted, the brand will review and confirm the project’s completion.",
      action: (
        <div className="states-main-wrapper">
          <div className="stats">
            <div className="text blue">
              <CircularProgressbar value={20} strokeWidth={16} />
              <span className="white">
                {" "}
                {proposalDetails?.jobProgressInfo?.video_count || 0}/
                {proposalDetails?.videoPrice?.count}
              </span>
            </div>
            <div className="bottom">
              <img src={videofile} alt="videofile" />
              <span className="ex_small">Videos Created</span>
            </div>
          </div>
          <div className="stats">
            <div className="text pink">
              <CircularProgressbar value={20} strokeWidth={16} />
              <span className="white">
                {" "}
                {proposalDetails?.jobProgressInfo?.live_count || 0}/
                {proposalDetails?.livePrice?.count}
              </span>
            </div>
            <div className="bottom">
              <img src={liveIcon} alt="liveIcon" />
              <span className="ex_small">Livestreams</span>
            </div>
          </div>
        </div>
      ),
    },

    {
      eventKey: "3",
      title: "Brand Review & Completion",
      description:
        "Once all content is submitted, the brand will review and confirm the project’s completion.",
      action: (
        <>
          <div className="progress-wrapper-main mt-5">
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar
                  className="video-created-progress-bar blue"
                  value={
                    proposalDetails?.jobProgressInfo?.video_count
                      ? Math.floor(
                          (proposalDetails?.jobProgressInfo?.video_count /
                            proposalDetails?.videoPrice?.count) *
                            100
                        )
                      : 0
                  }
                  strokeWidth={13}
                />
                <strong className="total medium">
                  {proposalDetails?.jobProgressInfo?.video_count || 0}/
                  {proposalDetails?.videoPrice?.count}
                </strong>
              </div>
              <div className="text-wrapper">
                <h3>Videos Created</h3>
                <ul className="list-wrapper">
                  <li>
                    <span className="label gray"></span> Expected Videos
                  </li>
                  <li>
                    <span className="label blue"></span> Videos Created
                  </li>
                </ul>
              </div>
            </div>
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar
                  className="video-created-progress-bar pink"
                  value={
                    proposalDetails?.jobProgressInfo?.live_count
                      ? Math.floor(
                          (proposalDetails?.jobProgressInfo?.live_count /
                            proposalDetails?.livePrice?.count) *
                            100
                        )
                      : 0
                  }
                  strokeWidth={13}
                />
                <strong className="total medium">
                  {proposalDetails?.jobProgressInfo?.live_count || 0}/
                  {proposalDetails?.livePrice?.count}
                </strong>
              </div>
              <div className="text-wrapper">
                <h3>Live Streams</h3>
                <ul className="list-wrapper">
                  <li>
                    <span className="label gray"></span> Expected Lives
                  </li>
                  <li>
                    <span className="label pink"></span> Live Streamed
                  </li>
                </ul>
              </div>
            </div>
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar
                  className="video-created-progress-bar gradient"
                  value={
                    proposalDetails?.generated_gmv?.amount
                      ? Math.floor(
                          (Number(proposalDetails?.generated_gmv?.amount) /
                            proposalDetails?.applicationId?.expected_gmv) *
                            100
                        )
                      : 0
                  }
                  strokeWidth={13}
                />
                <strong className="total medium">
                  {proposalDetails?.generated_gmv?.amount_formatted}
                </strong>
              </div>
              <div className="text-wrapper">
                <h3>GMV Generated</h3>
                <ul className="list-wrapper">
                  <li>
                    <span className="label gray"></span> Expected GMV
                  </li>
                  <li>
                    <span className="label gradient"></span> Generated GMV
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {proposalDetails?.applicationId?.shouldAutoRenew &&
            proposalDetails.status === "completed" &&
            !proposalDetails?.isRenewed && (
              <>
                <div className="notification-wrapper">
                  <HiMiniInformationCircle />
                  <strong>
                    {proposalDetails?.jobId?.brandName} was impressed with your
                    performance and would like to keep working with you
                  </strong>
                </div>
                <div className="attatch-link-wrapper">
                  <button className="custon-btn light" onClick={handleRenewJob}>
                    {loadings?.renewJobAsyncThunk ? (
                      <Spinner size="sm" />
                    ) : (
                      <>
                        <TbRefresh /> Accept renewal
                      </>
                    )}
                  </button>
                </div>
              </>
            )}
        </>
      ),
    },
    {
      eventKey: "4",
      title: "Payout",
      description: "Receive your payout from the brand",
      action: (
        <>
          {proposalDetails?.paymentInfo?.hasSent ? (
            <>
              {proposalDetails?.creatorPaymentConfirmed ? (
                <button className="custon-btn light">Payment Confirmed</button>
              ) : (
                <button
                  className="custon-btn light"
                  onClick={handleUpdateJobTask}
                >
                  {isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <>
                      <img src={confirmPayment} alt="confirmPayment" /> Confirm
                      Payment
                    </>
                  )}
                </button>
              )}
            </>
          ) : (
            <button className="custon-btn light">
              {!proposalDetails?.brandPaymentConfirmed
                ? "Awaiting Payment from Brand"
                : !proposalDetails?.adminPaymentConfirmed
                ? "Awaiting Payment from Admin"
                : "Something went wrong"}
            </button>
          )}
        </>
      ),
    },
  ];

  console.log("Proposal Details", proposalDetails);

  console.log("Status", proposalDetails?.status);

  const getClassName = (accordion) => {
    const status = proposalDetails?.status;
    const isPaymentSent = proposalDetails?.paymentInfo?.hasSent;

    const stepStatusMap = {
      0:
        proposalDetails?.jobId?.product_id ||
        proposalDetails?.sampleStatus === "requested"
          ? "accordion-item complete"
          : "accordion-item active",
      1:
        proposalDetails?.sampleStatus === "received"
          ? "accordion-item complete"
          : "accordion-item active",
      2:
        (proposalDetails?.videoPrice?.count &&
          proposalDetails?.livePrice?.count &&
          proposalDetails?.jobProgressInfo?.video_count >=
            proposalDetails.videoPrice.count &&
          proposalDetails?.jobProgressInfo?.live_count >=
            proposalDetails.livePrice.count) ||
        (proposalDetails?.videoPrice?.count &&
          !proposalDetails?.livePrice?.count &&
          proposalDetails?.jobProgressInfo?.video_count >=
            proposalDetails.videoPrice.count) ||
        (proposalDetails?.livePrice?.count &&
          !proposalDetails?.videoPrice?.count &&
          proposalDetails?.jobProgressInfo?.live_count >=
            proposalDetails.livePrice.count)
          ? "accordion-item complete"
          : "accordion-item active",
      3: proposalDetails?.brandApproval
        ? "accordion-item complete"
        : "accordion-item active",
      4: proposalDetails?.creatorPaymentConfirmed
        ? "accordion-item complete"
        : "accordion-item active",
    };
    return stepStatusMap[accordion.eventKey] || "accordion-item";
  };

  return (
    <div className="track-job-steps-content-wrapper">
      <Accordion
        defaultActiveKey={
          accordionData.find((step) => getClassName(step).includes("active"))
            ?.eventKey
        }
      >
        {accordionData.map((item, index) => (
          <Accordion.Item
            key={item.eventKey}
            eventKey={item.eventKey}
            className={getClassName(item)}
          >
            <Accordion.Header>
              <div className="step-wrapper">
                <div className="count-wrapper">
                  {getClassName(item).includes("complete") ? (
                    <FaCheck />
                  ) : (
                    <span>{+item.eventKey + 1}</span>
                  )}
                </div>
                <div className="contetn-wrapper">
                  <strong className="medium">{item.title}</strong>
                  <p>{item.description}</p>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="attatch-link-wrapper">{item.action}</div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      <LinkTrackingUrlModal
        editModalState={editModalState}
        editModalCloseHandler={editModalCloseHandler}
        proposalDetails={proposalDetails}
      />
    </div>
  );
}
