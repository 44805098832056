import numeral from "numeral";

export const getGMV = (json) => {
  const creatorStats = json?? {};

  return +creatorStats?.med_gmv_revenue?.value?.value
    ?
    numeral(creatorStats?.med_gmv_revenue?.value?.value ?? 0).format('$0a')
    :
    creatorStats?.med_gmv_revenue_range?.value ?? "$0"
};
export const getGMVPerVideo = (json) => {
  const creatorStats = json ?? {};
  const parseRevenueValue = (value) => {
    if (!value) return 0; // Return 0 if the value is undefined or null
    if (!isNaN(+value)) {
      return +value;
    }
    // Handle a range like "$1K-$10K" or "$10-$100"
    const rangeMatch = value.match(/^\$(\d+)(K)?-\$(\d+)(K)?$/);
    if (rangeMatch) {
      const lowerBound = Number(rangeMatch[1]) * (rangeMatch[2] ? 1000 : 1); // Check for 'K' in lower bound
      const upperBound = Number(rangeMatch[3]) * (rangeMatch[4] ? 1000 : 1); // Check for 'K' in upper bound
      return (lowerBound + upperBound) / 2;
    }
    const singleValueMatch = value.match(/^\$(\d+)(K)?(\+)?$/);
    if (singleValueMatch) {
      console.log('singleValueMatch', singleValueMatch);
      return Number(singleValueMatch[1]) * (singleValueMatch[2] ? 1000 : 1); // Convert "10K+" to 10000
    }
    const singleDollarValueMatch = value.match(/^\$(\d+)$/);
    if (singleDollarValueMatch) {
      console.log('singleDollarValueMatch', singleDollarValueMatch);
      return Number(singleDollarValueMatch[1]); // Convert "$10" to 10
    }
    return 0;
  };
  const result = (() => {
    // Check for med_gmv_revenue first, fall back to med_gmv_revenue_range
    const medGmvRevenueValue = (creatorStats?.med_gmv_revenue?.value?.value && !isNaN(+creatorStats?.med_gmv_revenue?.value?.value)) ? creatorStats?.med_gmv_revenue?.value?.value :
      (creatorStats?.med_gmv_revenue_range?.value ||
        "$0"); // Fallback to "$0" if both are undefined
    const medGmvRevenue = parseRevenueValue(medGmvRevenueValue);
    const videoPublishCnt = Number(creatorStats?.video_publish_cnt_30d?.value) || 0;
    const revenuePerVideo = videoPublishCnt > 0 ? medGmvRevenue / videoPublishCnt : 0;
    return `$${numeral(revenuePerVideo).format("0a")}`;
  })();
  return `$${numeral(result).format("0a")}`;
};





const parseRevenueValue = (value) => {
  if (!value) return 0; // Return 0 if the value is undefined or null
  if (!isNaN(+value)) {
    return +value;
  }
  // Handle a range like "$1K-$10K" or "$10-$100"
  const rangeMatch = value.match(/^\$(\d+)(K)?-\$(\d+)(K)?$/);
  if (rangeMatch) {
    const lowerBound = Number(rangeMatch[1]) * (rangeMatch[2] ? 1000 : 1); // Check for 'K' in lower bound
    const upperBound = Number(rangeMatch[3]) * (rangeMatch[4] ? 1000 : 1); // Check for 'K' in upper bound
    return (lowerBound + upperBound) / 2;
  }
  const singleValueMatch = value.match(/^\$(\d+)(K)?(\+)?$/);
  if (singleValueMatch) {
    console.log('singleValueMatch', singleValueMatch);
    return Number(singleValueMatch[1]) * (singleValueMatch[2] ? 1000 : 1); // Convert "10K+" to 10000
  }
  const singleDollarValueMatch = value.match(/^\$(\d+)$/);
  if (singleDollarValueMatch) {
    console.log('singleDollarValueMatch', singleDollarValueMatch);
    return Number(singleDollarValueMatch[1]); // Convert "$10" to 10
  }
  return 0;
};

export const newGetGMV = (medGmvRevenueValue, medGmvRevenueRangeValue) => {
  const medGmvRevenue = parseRevenueValue(medGmvRevenueValue);
  return medGmvRevenue
    ? numeral(medGmvRevenue).format('$0a')
    : parseRevenueValue(medGmvRevenueRangeValue) ?? "$0";
};
export const newGetGMVPerVideo = (medGmvRevenueValue, medGmvRevenueRangeValue, videoPublishCntValue) => {
  const medGmvRevenueValueParsed = medGmvRevenueValue && !isNaN(+medGmvRevenueValue) ? medGmvRevenueValue : parseRevenueValue(medGmvRevenueRangeValue) || "$0";
  console.log("medGmvRevenueValueParsed", medGmvRevenueValueParsed);
  const medGmvRevenue = parseRevenueValue(medGmvRevenueValueParsed);
  console.log("medGmvRevenue", medGmvRevenue)
  const videoPublishCnt = Number(videoPublishCntValue) || 0;
  console.log("videoPublishCnt", videoPublishCnt)
  const revenuePerVideo = videoPublishCnt > 0 ? medGmvRevenue / videoPublishCnt : 0;
  console.log("revenuePerVideo", revenuePerVideo)
  return `$${numeral(revenuePerVideo).format("0a")}`;
};



// GMV (actual for brand)
// Price paid (actual)
// ROI = Price paid / GMV
export const getRoi = (medGmvRevenueValue, medGmvRevenueRangeValue, price)=>{
  const gmv = newGetGMV(medGmvRevenueValue, medGmvRevenueRangeValue);
  const roi = gmv && price ? numeral(price / gmv).format("0.00") : "0.00";
  return roi;
}

// price is all videos price 
export const getRoiPerVideo = (medGmvRevenueValue, medGmvRevenueRangeValue, price, videoPublishCntValue)=>{
  const gmvPerVideo = newGetGMVPerVideo(medGmvRevenueValue, medGmvRevenueRangeValue, videoPublishCntValue);
  const pricePerVideo = parseRevenueValue(price) / Number(videoPublishCntValue);
  const roiPerVideo = gmvPerVideo && pricePerVideo ? numeral(pricePerVideo / parseRevenueValue(gmvPerVideo)).format("0.00") : "0.00";
  return roiPerVideo;
}