import React from "react";

import { FiArrowRight } from "react-icons/fi";
import welcomeBmcImg from "../assets/images/welcome-bmc-img.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setScreenLock, updateProfile } from "../redux/pagesSlices/authSlice";

export default function JobPostWelcomeToBMC() {
  const d = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="job-post-inner-wrapper">
      <div className="welcome-to-bmc-wrapper">
        <div className="img-wrapper">
          <img src={welcomeBmcImg} alt="welcomeBmcImg" />
        </div>
        <div className="modal-text-wrapper">
          <h2>Welcome to BMC 🎉</h2>
          <p className="medium medium-bold">
            You’re all set up and ready to go! Start exploring your dashboard or{" "}
            <br /> take a quick tour to get familiar with everything.{" "}
          </p>
        </div>
        <div className="welcome-footer">
          <Link
            to="/dashboard/home"
            onClick={() => {
              d(setScreenLock(false));
            }}
            className="btn-style"
          >
            Give me a walkthrough <FiArrowRight />
          </Link>
          <Link
            to="#"
            onClick={() => {
              d(
                updateProfile({
                  data: {
                    isUserTourOpen: true,
                  },
                  callBack:()=>{
                    d(setScreenLock(false));
                    navigate("/dashboard/home");
                  }
                })
              );              
            }}
            className="blue medium-bold"
          >
            I don’t need help I’ll do it myself
          </Link>
        </div>
      </div>
    </div>
  );
}
