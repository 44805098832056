import axios from "axios";
import { basePath } from "../constants.js";
import { ApiRequests } from "./ApiRequests.js";
import { toast } from "react-toastify";

function clearCookies() {
  const cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    const [name] = cookie.split("=");
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}

const apiService = axios.create({
  baseURL: basePath,
  timeout: 350000,
  withCredentials: true,
});

// Add request interceptor
apiService.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("access-token");
    // Set headers with token
    config.headers = {
      Accept: "application/json, text/plain, */*",
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle 401 errors
apiService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the request URL includes login or register, if so, don't refresh the token
    const loginRegisterEndpoints = [
      "/login",
      "/register",
      "/refresh-tokens",
      "/logout",
    ];
    const isLoginOrRegisterRequest = loginRegisterEndpoints.some((endpoint) =>
      originalRequest.url.includes(endpoint)
    );

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      !isLoginOrRegisterRequest
    ) {
      originalRequest._retry = true;

      try {
        // Get the refresh token
        const refreshToken = localStorage.getItem("refresh-token");

        // Check if refreshToken exists to avoid null or undefined refresh attempts
        if (!refreshToken || refreshToken === "null") {
          localStorage.clear();
          sessionStorage.clear();
          clearCookies();
          if (!window.location.pathname.startsWith("/new-password")) {
            window.location.href = "/sign-in";
          }
          return Promise.reject(error);
        }

        // Use the existing ApiRequests to refresh the token
        const response = await ApiRequests.refreshToken({ refreshToken });
        localStorage.setItem("access-token", response.data.access.token);
        localStorage.setItem("refresh-token", response.data.refresh.token);

        // Update the authorization header with the new token
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${response.data.access.token}`;

        // Retry the original request with the new token
        return apiService(originalRequest);
      } catch (refreshError) {
        // Handle the case where refresh token fails (e.g., redirect to login)
        console.error("Token refresh failed: ", refreshError);
        toast.error(refreshError.message);
        localStorage.clear();
        sessionStorage.clear();
        clearCookies();
        if (!window.location.pathname.startsWith("/new-password")) {
          window.location.href = "/sign-in";
        }
        return Promise.reject(refreshError);
      }
    }

    // If error response status is 401 and retry failed, or any other error status
    return Promise.reject(error);
  }
);

export default apiService;
