// ** Reducers Imports
import auth from "./pagesSlices/authSlice";
import user from "./pagesSlices/userSlice";
import contract from "./pagesSlices/contractSlice";
import brand from "./pagesSlices/brandSlice";
import creator from "./pagesSlices/creatorSlice";
import job from "./pagesSlices/jobSlice";
import application from "./pagesSlices/applicationSlice";
import error from "./errors/handleErrorsAndPayloads";
import chat  from "./pagesSlices/chatSlice";
import payment  from "./pagesSlices/paymentSlice";
import { combineReducers } from "@reduxjs/toolkit";
import campaign  from "./pagesSlices/campaignSlice";
import invitation from "./pagesSlices/invitationSlice"
import jobTask from "./pagesSlices/jobTaskSlice"

const rootReducer = combineReducers({
  user,
  contract,
  creator,
  application,
  brand,
  job,
  error,
  auth,
  chat,
  payment,
  campaign,
  invitation,
  jobTask,
});

export default rootReducer;
