import React, { useEffect, useState } from "react";

import bankIcon from "../assets/images/icons/bank-icon.svg";
import stripeIcon from "../assets/images/icons/stripe-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  connectAccountAsyncThunk,
  getConnectedAccountAsyncThunk,
} from "../redux/pagesSlices/paymentSlice";
import { Spinner } from "react-bootstrap";
import useStripeConnect from "../hooks/useStripeConnect";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

export default function JobPostConnectStripeAccountSlide({
  values,
  setOnboardingExited,
  setFieldValue,
}) {
  const dispatch = useDispatch();
  const { connectedAccount, loadings } = useSelector((s) => s.payment);
  const stripeConnectInstance = useStripeConnect(connectedAccount?.id);

  useEffect(() => {
    if (values?.stripe_account_id)
      dispatch(
        getConnectedAccountAsyncThunk({ id: values?.stripe_account_id })
      );
  }, [values?.stripe_account_id]);

  const handleConnect = async () => {
    const connectedId = await dispatch(connectAccountAsyncThunk({})).unwrap();
    if (connectedId) {
      setFieldValue("stripe_account_id", connectedId);
    }
  };
  return (
    <div className="job-post-inner-wrapper">
      <dir>
        <div className="modal-text-wrapper">
          <h2>Connect your stripe account</h2>
          <p className="medium medium-bold">
            BMC does not impose any additional charges; however, please note
            that standard <br /> Stripe transaction fees may apply.
          </p>
        </div>
        <div className="stripte-banks-detail-wrapper">
          <div className="bannk-img-wrapper">
            <ul className="bank-img">
              <li>
                <img src={bankIcon} alt="bankIcon" />
              </li>
              <li>
                <img src={stripeIcon} alt="stripeIcon" />
              </li>
            </ul>
          </div>
          <div className="content-wrapper">
            {Object.entries(connectedAccount).length > 0 ? (
              <>
                {stripeConnectInstance &&
                connectedAccount?.capabilities?.transfers !== "active" ? (
                  <ConnectComponentsProvider
                    connectInstance={stripeConnectInstance}
                  >
                    <ConnectAccountOnboarding
                      onExit={() => {
                        setOnboardingExited(true);
                        dispatch(
                          getConnectedAccountAsyncThunk({
                            id: values?.stripe_account_id,
                          })
                        );
                      }}
                    />
                  </ConnectComponentsProvider>
                ) : (
                  <>
                    <strong className="semi_bold medium dark">
                      Connected Account
                    </strong>
                    <p className="medium">
                      <strong>Email:</strong>
                      <span>{connectedAccount?.email}</span>
                      <br />
                      <strong>Account Type:</strong>
                      <span>{connectedAccount?.type}</span>
                    </p>
                  </>
                )}
              </>
            ) : (
              <>
                <strong className="semi_bold medium dark">Connect Bank</strong>
                <p className="medium">
                  <span>
                    Link your bank account to start receiving your payouts.
                  </span>{" "}
                  We use Stripe to link your bank account.{" "}
                  <a href="#" className="blue underline">
                    Learn more
                  </a>{" "}
                </p>
                <button
                  onClick={handleConnect}
                  disabled={loadings?.connectAccountAsyncThunk}
                  className="btn-style large_font"
                >
                  {loadings?.connectAccountAsyncThunk ? (
                    <Spinner size="sm" />
                  ) : (
                    "Connect Bank"
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </dir>
    </div>
  );
}
