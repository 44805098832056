import React from "react";

import jobImg from "../assets/images/job-img.svg";
import brandImg from "../assets/images/brand-img.svg";
import videoFileIcon from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import applyNowRightArrow from "../assets/images/icons/apply-now-right-arrow.svg";
import { returnArray } from "../utils/utils";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { dateFormat } from "../utils/format";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

export default function JobCardComponent({
  job,
  jobTitle,
  // brandImg,
  brandName,
  jobPostedTime,
  className,
  key,
}) {
  const router = useNavigate();
  // console.log("job", job);

  return (
    <div className={className} key={key}>
      <div className="job-card-main-wrapper">
        <div className="job-img-wrapper">
          <Link to={`/dashboard/job/${job._id ?? job.id}`}>
            <img
              src={
                returnArray(job?.image).length > 0
                  ? parseImagePathToURL(job?.image[0])
                  : jobImg
              }
              alt="jobImg"
              className="job-img"
            />
          </Link>
        </div>
        <div className="job-content-wrapper">
          <div className="brand-top-info">
            <div className="brand-info">
              <Link to={`#`}>
                {job?.brandInfo?.avatar ? (
                  <img
                    src={parseImagePathToURL(job?.brandInfo?.avatar)}
                    alt="brandImg"
                  />
                ) : (
                  <img src={brandImg} alt="brandImg" />
                )}
              </Link>
              <p className="medium-bold dark">
                <a href="#">{job.brandName || brandName}</a>
              </p>
            </div>
            <div className="platforms-wrapper">
              {returnArray(job?.types).map((type, index) => (
                <div
                  key={index}
                  className={`platform ${
                    type == "live" ? "light-pink-bg" : "light-gray-bg"
                  }`}
                >
                  <img
                    src={type === "live" ? liveIcon : videoFileIcon}
                    alt="videoFileIcon"
                  />
                  <span>{type}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="job-title-wrapper">
            <strong className="dark">
              <Link to={`/dashboard/job/${job._id ?? job.id}`}>
                {job.jobTitle || jobTitle}
              </Link>
            </strong>
            <span className="small">
              {"Updated" + " " + moment(job?.updatedAt).fromNow()}
            </span>
          </div>
          <div className="categories-wrapper">
            <ul className="categories-list">
              {returnArray(job?.categories?.slice(0, 3) || []).map(
                (category, index) => (
                  <>
                    <li key={index}>{category}</li>
                  </>
                )
              )}
            </ul>
          </div>
        </div>
        <div
          className="apply-now-btn"
          // onClick={()=>{
          //   router(`/dashboard/job/${job._id ?? job.id}`)
          // }}
        >
          <Link
            to={`/dashboard/job/${job._id ?? job.id}`}
            className="btn-style full"
          >
            Apply now <img src={applyNowRightArrow} alt="applyNowRightArrow" />
          </Link>
        </div>
      </div>
    </div>
  );
}
