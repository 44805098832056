import React from "react";
import { ErrorMessage, Field } from "formik";

export default function JobPostConnectTikTokShop({ errors }) {
  return (
    <div className="job-post-inner-wrapper">
      <div>
        <div className="modal-text-wrapper">
          <h2>Let's Connect Your TikTok Shop Affiliate Account</h2>
          <p className="medium medium-bold">
            Adding your TikTok Shop tikTokUserName helps us find the best brand
            <br />
            opportunities that match your shop’s profile and style.
          </p>
        </div>
        <div className="form-group">
          <label htmlFor="tikTokUserName" className="medium">
            Tiktok Shop Username
          </label>
          <Field
            type="text"
            name="tikTokUserName"
            id="tikTokUserName"
            className={`form-control blue-text ${
              errors.tikTokUserName ? "is-invalid" : ""
            }`}
            placeholder="Enter your TikTok Shop username"
          />
          <ErrorMessage
            component="div"
            className="invalid-feedback"
            name="tikTokUserName"
          />
        </div>
      </div>
    </div>
  );
}
