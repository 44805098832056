export const gendersList = ["male", "female", "other"];
export const sampleRecordList = {
  0: "all",
  10: "to review",
  20: "ready to ship",
  30: "shipped",
  40: "in progress",
  100: "completed",
  50: "canceled",
};
