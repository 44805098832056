import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { SlHandbag } from "react-icons/sl";
import { LuGlobe } from "react-icons/lu";
import { PiLinkSimpleBold } from "react-icons/pi";

import leftLongIcon from "../assets/images/icons/left-long-arrow.svg";
import mapIcon from "../assets/images/icons/map-icon.svg";
import jobImg from "../assets/images/icons/job-image.svg";
import proposalImg from "../assets/images/proposal-img.svg";
import videoMediaImg from "../assets/images/video-media-img.svg";
import videoIcon from "../assets/images/icons/video-file-wrapper.svg";
import instagramIcon from "../assets/images/icons/instagram-colored-icon.svg";
import facebookIcon from "../assets/images/icons/facebook-colored-icon.svg.svg";
import tiktokIcon from "../assets/images/icons/tiktok-colored-icon.svg.svg";
import xIcon from "../assets/images/icons/x-colored-icon.svg.svg";
import youtubeIcon from "../assets/images/icons/youtube-colored-icon.svg.svg";
import LiveIcon from "../assets/images/icons/live-icon.svg";
import NoLinksComponent from "../components/NoLinksComponent";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { returnArray } from "../utils/utils";
import LoaderComponent from "../components/LoaderComponent";
import { MdVerifiedUser } from "react-icons/md";
import { dateFormat } from "../utils/format";
import moment from "moment";

export default function ProposalDetailPage() {
  const d = useDispatch();
  const { id } = useParams();
  const [viewMorePainPoints, setViewMorePainPoints] = useState(false);
  const proposalDetails = useSelector((s) => s.application.application);
  const videoRefs = useRef([]);
  const [playingIndex, setPlayingIndex] = useState(null); // Track which video is playing
  const loading = useSelector(
    (s) => s.application?.loadings?.getApplicationAsyncThunk
  );

  useEffect(() => {
    if (id) d(getApplicationAsyncThunk(id));
  }, [d, id]);

  const videoPrices = returnArray(proposalDetails?.offeredVideoPrice);
  const livePrices = returnArray(proposalDetails?.offeredLivePrice);
  const brand = proposalDetails?.brandId;
  const job = proposalDetails?.jobId ?? {};
  console.log("Application Details", proposalDetails);
  const brandWebsite = proposalDetails?.brandId?.website;

  const videoArray = job?.videoUrl?.split(",").map((url) => url.trim());

  const brandName = proposalDetails?.brandId?.name;
  const numberOfVideos = returnArray(videoPrices).reduce(
    (acc, item) => acc + item.count,
    0
  );
  const numberOfLives = returnArray(livePrices).reduce(
    (acc, item) => acc + item.count,
    0
  );
  const handlePlay = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement.paused) {
      videoElement.play();
      setPlayingIndex(index);
    } else {
      videoElement.pause();
      setPlayingIndex(null);
    }
  };

  const socialIconsMap = {
    Instagram: instagramIcon,
    Facebook: facebookIcon,
    TikTok: tiktokIcon,
    X: xIcon, // Assuming X corresponds to the 'Twitter' replacement
    YouTube: youtubeIcon,
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="inner-page-wrapper active m-0">
      <div className="proposal-detail-page">
        <div className="upper-section">
          <div className="container">
            <div className="top-bar">
              <Link to="/dashboard/my-proposals" className="btn-style text">
                <img src={leftLongIcon} alt="leftLongIcon" /> Back
              </Link>
            </div>
            <div className="proposal-title-wrapper">
              <div className="row">
                <div className="col-md-8 mb-4 mb-md-0">
                  <div className="title-wrapper">
                    <h2>{job?.jobTitle}</h2>
                    <div className="job-info">
                      <div className="main-wrapper">
                        <div className="img-wrapper">
                          <img
                            src={
                              brand?.avatar
                                ? parseImagePathToURL(brand?.avatar)
                                : jobImg
                            }
                            alt="jobImg"
                          />
                        </div>
                        <div className="text-wrapper">
                          <div className="title">
                            <strong className="medium">
                              <a href="#" className="blue">
                                {job?.brandName}
                              </a>
                            </strong>
                            <div className="location">
                              <img src={mapIcon} alt="mapIcon" />
                              <p className="medium dark">{job?.location}</p>
                            </div>
                          </div>
                          <div className="link">
                            <p className="dark medium">
                              <Link target="_blank" to={brand?.website}>
                                {brand?.website}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="btn-wrapper text-end">
                    <Link
                      to={`/dashboard/proposals/${
                        proposalDetails?.id ?? proposalDetails?._id
                      }/track`}
                      className="btn-style white-bg large_font"
                    >
                      Track proposal
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="project-detail-section">
          <div className="container ">
            <div className="row">
              <div className="col-md-8">
                <div className="about-project-detal-wrapper">
                  <div className="about-job-content-wrapper">
                    <h3>Project details</h3>
                    <div className="text-wrapper">
                      <strong>Job description</strong>
                      <p>{brand?.description}</p>
                    </div>
                    <div className="text-wrapper">
                      <strong>Pain points</strong>
                      <p>
                        {job?.jobAttributes?.painPoints?.length > 200
                          ? viewMorePainPoints === true
                            ? job?.jobAttributes?.painPoints
                            : job?.jobAttributes?.painPoints?.slice(0, 200) +
                              "..."
                          : job?.jobAttributes?.painPoints}
                      </p>

                      {job?.jobAttributes?.painPoints?.length > 200 &&
                        !viewMorePainPoints && (
                          <button
                            onClick={() => setViewMorePainPoints(true)}
                            className="btn btn-link text-decoration-none blue"
                          >
                            View more <IoIosArrowDown />
                          </button>
                        )}
                    </div>
                  </div>
                  <div className="proposal-content-wrapper">
                    <h3>Proposal</h3>
                    {proposalDetails?.coverLetter && (
                      <div className="text-wrapper">
                        <strong>What’s Unique About You?</strong>
                        <p>{proposalDetails?.coverLetter}</p>
                      </div>
                    )}
                  </div>
                  <div className="price-rates-wrapper">
                    <h3>Prices and rates</h3>
                    <div className="pricing-listing-wrapper">
                      <strong>Video prices</strong>
                      <ul className="listing">
                        {videoPrices.length > 0 ? (
                          videoPrices.map((item) => (
                            <li key={item._id}>
                              <span className={'small'}>
                                {item.count} Videos per{" "}
                                {item.durationCount > 1
                                  ? `${item.durationCount} ${item.duration}s`
                                  : item.duration}
                              </span>{" "}
                              <h3 className="dark bold">${item.price}</h3>
                            </li>
                          ))
                        ) : (
                          <li>
                            <span>Video Price Not Set</span>{" "}
                            {/* <span className="dark bold">${0}</span> */}
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="pricing-listing-wrapper">
                      <strong>Live prices</strong>
                      <ul className="listing">
                        {livePrices.length > 0 ? (
                          livePrices?.map((item) => (
                            <li key={item._id}>
                              <span className={'small'}>
                                {item.count} Videos per{" "}
                                {item.durationCount > 1
                                  ? `${item.durationCount} ${item.duration}s`
                                  : item.duration}
                              </span>{" "}
                              <h3 className="dark bold">${item.price}</h3>
                            </li>
                          ))
                        ) : (
                          <li>
                            <span>Live Price Not Set</span>{" "}
                            {/* <span className="dark bold">${0}</span> */}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="media-and-resources-wrapper">
                    <h3>Media & Resources</h3>
                    <div className="media-wrapper">
                      <strong className="bold">Photos</strong>
                      <ul className="images-media-wrapper">
                        {job?.image?.map((item, index) => (
                          <li key={index}>
                            <img
                              src={parseImagePathToURL(item)}
                              alt="proposalImg"
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="media-wrapper">
                      <strong className="bold">Videos</strong>
                      <ul className="videos-media-wrapper">
                        {videoArray?.length > 0 &&
                          videoArray.map((video, index) => (
                            <li key={index}>
                              <video
                                ref={(el) => (videoRefs.current[index] = el)} // Set ref for each video
                                controls
                                width="600"
                                poster={videoMediaImg}
                                onPlay={() => setPlayingIndex(index)} // Set index when video starts playing
                                onPause={() => setPlayingIndex(null)} // Reset index when video is paused
                              >
                                <source src={video} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                              {playingIndex !== index && (
                                <div
                                  className="icon-wrapper"
                                  onClick={() => handlePlay(index)}
                                >
                                  <FaPlay />
                                </div>
                              )}
                            </li>
                          ))}
                      </ul>
                    </div>
                    {/* <div className="media-wrapper">
                      <strong className="bold">Links</strong>
                      {videoArray?.length > 0 ? (
                        <ul className="links-media-wrapper">
                          {videoArray?.map((item, index) => (
                            <li key={index}>
                              <a href={item} className="blue">
                                {item}
                              </a>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <NoLinksComponent />
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="summary-main-wrapper">
                  <strong className="large dark">Job details</strong>
                  <div className="top-bar-wrapper">
                    <div className="text">
                      <strong>Job posted</strong>
                      <p className="dark_text semi_bold">
                        {dateFormat(job?.createdAt)}{" "}
                        <span className="small gray">
                          {" "}
                          ({moment(job?.createdAt).fromNow()})
                        </span>
                      </p>
                    </div>
                    <div className="text">
                      <strong>Content type</strong>
                      <ul className="proposal-type-list">
                        {returnArray(job?.types).map((type, index) => (
                          <li
                            key={index}
                            className={`${
                              type == "video"
                                ? "light-gray-bg"
                                : "light-pink-bg"
                            }`}
                          >
                            <img
                              src={type === "video" ? videoIcon : LiveIcon}
                              alt="Icon"
                            />
                            {type}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="text">
                      <strong>Categories</strong>
                      <ul className="categories-list">
                        {returnArray(job?.categories).map((category, index) => (
                          <li key={index}>{category}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="text">
                      <strong>Preferred ages</strong>
                      <p className="light-bg">
                        {returnArray(job?.ageRange).join(", ")}
                      </p>
                    </div>
                    <div className="text">
                      <strong>Preferred gender</strong>
                      <p className="light-bg">{job?.gender}</p>
                    </div>
                    <div className="text">
                      <strong>Location</strong>
                      <p className="dark_text medium semi_bold">
                        United States
                      </p>
                    </div>
                    <div className="text">
                      <strong>Language</strong>
                      <p className="dark_text medium semi_bold">English</p>
                    </div>
                  </div>
                  <strong className="large dark">About client</strong>
                  <div className="top-bar-wrapper">
                    <div className="text">
                      <div className="main-wrapper">
                        <div className="img-wrapper">
                          <a href="#">
                            <img
                              src={
                                brand?.avatar
                                  ? parseImagePathToURL(brand?.avatar)
                                  : proposalImg
                              }
                              alt="proposalImg"
                            />
                          </a>
                        </div>
                        <div className="text-wrapper">
                          <strong className="dark medium semi_bold">
                            <Link to={brandWebsite}>{brandName}</Link>
                          </strong>
                          <div className="name">
                            <MdVerifiedUser style={{ color: "#578BFC" }} />{" "}
                            <strong>Verified brand</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text">
                      <div className="name">
                        <IoLocationOutline />
                        <strong>Location</strong>
                      </div>
                      <p className="dark_text medium semi_bold">
                        {job?.address}
                      </p>
                    </div>
                    <div className="text">
                      <div className="name">
                        <SlHandbag />
                        <strong>Total jobs</strong>
                      </div>
                      <p className="dark_text medium semi_bold">
                        23 posted jobs
                      </p>
                    </div>
                    <div className="text">
                      <div className="name">
                        <LuGlobe />
                        <strong>Website</strong>
                      </div>
                      <p className="dark_text medium semi_bold">
                        <Link to={brandWebsite} className="link">
                          {brandWebsite}
                        </Link>
                      </p>
                    </div>
                    <div className="text">
                      <div className="name">
                        <PiLinkSimpleBold />
                        <strong>Socials</strong>
                      </div>
                      <ul className="social-icons">
                        {brand?.socials?.map((social) => (
                          <li key={social._id}>
                            <Link
                              to={social.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={socialIconsMap[social.type]}
                                alt={`${social.type} icon`}
                              />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
