import React, { useEffect, useState } from "react";
import notificationIcon from "../assets/images/icons/notification-icon.svg";
import settinglinelight from "../assets/images/icons/settings-light-icon.svg";
import checkfill from "../assets/images/icons/check-fill.svg";
import logoutlightIcon from "../assets/images/icons/logout-light-icon.svg";
import removeicon from "../assets/images/icons/remove-icon.svg";
import creditcardlight from "../assets/images/icons/Credit-card-light.svg";
import secureIcon from "../assets/images/icons/secure-icon.svg";
import Google from "../assets/images/icons/google-icon.svg";
import AddBlueIcon from "../assets/images/icons/AddBlueIcon.svg";
import deletIcon from "../assets/images/icons/delete-icon.svg";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeftLong } from "react-icons/fa6";
import userlight from "../assets/images/icons/user-light-icon.svg";
import {
  updateProfile,
  userLogoutAsyncThunk,
} from "../redux/pagesSlices/authSlice";

import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { Spinner } from "react-bootstrap";
import ChangePasswodModal from "../components/modals/ChangePasswodModal";
import { timeZones } from "../utils/timeZonesList";
import DeleteAccountModal from "../components/modals/DeleteAccountModal";
import { toast } from "react-toastify";
import PaymentTab from "../components/profileSettings/PaymentTab";
import { updateCreatorAsyncThunk } from "../redux/pagesSlices/creatorSlice";

const maxAdditionalEmails = 1;

const notificationValidationSchema = Yup.object().shape({
  settings: Yup.object()
    .shape({
      emails: Yup.boolean(),
      notifications: Yup.boolean(),
      commentsNotifications: Yup.boolean(),
      newNotifications: Yup.boolean(),
      jobNotifications: Yup.boolean(),
      systemNotifications: Yup.boolean(),
    })
    .required("Notification settings are required"),
});

const preferencesValidationSchema = Yup.object().shape({
  secondaryEmail: Yup.array()
    .of(Yup.string().email("Invalid email address"))
    .max(
      maxAdditionalEmails,
      `You can only add up to ${maxAdditionalEmails} emails.`
    ),
  timeZone: Yup.string(),
  hourFormat: Yup.number(),
});

const Settings = () => {
  const [activeTab, setActiveTab] = useState("tab6");
  const [tabContentMobile, setTabContentMobile] = useState(false);
  const [currentPass, setCurrentPass] = useState(null);
  const [currentPassError, setCurrentPassError] = useState(false);
  const [passModel, setPassModel] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const { user, profile, creator } = useSelector((s) => s.auth);
  const d = useDispatch();
  const setActiveTabHandler = (tabName) => {
    setActiveTab(tabName);
    setTabContentMobile(true);
  };

  const tabContentMobileCloseHandler = () => {
    setTabContentMobile(false);
  };

  const handleSubmitNotification = (values, { setSubmitting }) => {
    setSubmitting(true);
    d(
      updateProfile({
        data: values,
        callBack: () => {
          toast.success("Your Information has been updated successfully!");
        },
      })
    ).then(() => {
      setSubmitting(false);
    });
  };

  const updateCreatorSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .min(3, "First Name must be greater than 3 characters"),
    lastName: Yup.string()
      .required("Last Name is required")
      .min(3, "Last Name must be greater than 3 characters"),
  });

  const loading = useSelector((s)=>s.creator?.loadings?.updateCreatorAsyncThunk)

  const handleSubmitPreferences = (values, { setSubmitting }) => {
    setSubmitting(true);
    const data = { ...values };
    if (values.secondaryEmail && values.secondaryEmail.length > 0) {
      if (values.secondaryEmail[0] !== user.secondaryEmail)
        data.secondaryEmail = values.secondaryEmail[0];
      else delete data.secondaryEmail;
    } else {
      delete data.secondaryEmail;
    }

    d(
      updateProfile({
        data,
        callBack: () => {
          toast.success("Your Information has been updated successfully!");
        },
      })
    ).then(() => {
      setSubmitting(false);
    });
  };
  const handleUpdateCreator = (values) => {
    d(updateCreatorAsyncThunk({
      data : values
    }))
  }
  const handleOpenPassModel = () => {
    if (!currentPass || currentPass === "") {
      setCurrentPassError(true);
      return;
    }
    setPassModel(true);
  };
  const handleClosePassModel = () => {
    setPassModel(false);
  };
  const handleOpenDeleteAccount = () => {
    setDeleteAccount(true);
  };
  const handleCloseDeleteAccount = () => {
    setDeleteAccount(false);
  };

  const [selectedValue, setSelectedValue] = useState("24");

  // Handle selection
  const handleSelect = (eventKey) => {
    setSelectedValue(eventKey);
  };

  return (
    <>
      <div className="settings-page">
        <div className="container">
          <div className="title">
            <span>Settings</span>
          </div>
          <div className="main-content">
            <div
              className={`sec1 d-md-block  ${
                tabContentMobile ? "d-none" : "d-block"
              }`}
            >
              <div className="settings-menu">
                <button
                  className={`menu-item ${
                    activeTab === "tab6" ? "active" : ""
                  }`}
                  onClick={() => setActiveTabHandler("tab6")}
                >
                  <div className="icon-parent">
                    <img
                      src={userlight}
                      alt="settinglinelight"
                      className="icon"
                    />
                  </div>
                  <span>Account</span>
                </button>
                <button
                  className={`menu-item ${
                    activeTab === "tab2" ? "active" : ""
                  }`}
                  onClick={() => setActiveTabHandler("tab2")}
                >
                  <div className="icon-parent">
                    <img
                      src={settinglinelight}
                      alt="settinglinelight"
                      className="icon"
                    />
                  </div>
                  <span>Preferences</span>
                </button>
                <button
                  className={`menu-item ${
                    activeTab === "tab5" ? "active" : ""
                  }`}
                  onClick={() => setActiveTabHandler("tab5")}
                >
                  <div className="icon-parent">
                    <img
                      src={creditcardlight}
                      alt="secureIcon"
                      className="icon"
                    />
                  </div>
                  <span>Pay out method</span>
                </button>
                <button
                  className={`menu-item ${
                    activeTab === "tab1" ? "active" : ""
                  }`}
                  onClick={() => setActiveTabHandler("tab1")}
                >
                  <div className="icon-parent">
                    <img
                      src={notificationIcon}
                      altfalse="notification"
                      className="icon"
                    />
                  </div>
                  <span>Notification settings</span>
                </button>
                <button
                  className={`menu-item ${
                    activeTab === "tab3" ? "active" : ""
                  }`}
                  onClick={() => setActiveTabHandler("tab3")}
                >
                  <div className="icon-parent">
                    <img src={secureIcon} alt="secureIcon" className="icon" />
                  </div>
                  <span>Password & Security</span>
                </button>

                <hr />
                <button
                  className={`menu-item ${
                    activeTab === "tab4" ? "active" : ""
                  }`}
                  onClick={() => d(userLogoutAsyncThunk())}
                >
                  <div className="icon-parent">
                    <img
                      src={logoutlightIcon}
                      alt="logoutlightIcon"
                      className="icon"
                    />
                  </div>
                  <span>Log out</span>
                </button>
                <button
                  className={`menu-item delete ${
                    activeTab === "tab5" ? "active" : ""
                  }`}
                  onClick={handleOpenDeleteAccount}
                >
                  <div className="icon-parent">
                    <img src={deletIcon} alt="deletIcon" className="icon" />
                  </div>
                  <span style={{ color: "#DB2719" }}>Delete account</span>
                </button>
              </div>
            </div>
            <div
              className={`sec2 d-md-block  ${
                tabContentMobile ? "d-block" : "d-none"
              }`}
            >
              {activeTab === "tab1" && (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    settings: { ...user?.settings },
                  }}
                  validationSchema={notificationValidationSchema}
                  onSubmit={handleSubmitNotification}
                >
                  {({ dirty, values, setFieldValue, isSubmitting }) => {
                    return (
                      <Form className="notification-settings">
                        <h2 className="settings-tab-ui-head d-flex align-items-center gap-3">
                          <FaArrowLeftLong
                            className="d-block d-md-none"
                            onClick={tabContentMobileCloseHandler}
                            style={{
                              width: 22,
                              height: 17,
                              color: "#1a202c",
                              borderRadius: 0,
                              minWidth: "auto",
                            }}
                          />
                          Notification settings
                        </h2>
                        <hr />
                        <div className="sec2-settings-context">
                          <div className="setting">
                            <div>
                              <h3>Push Notification</h3>
                              <p>
                                Allow push notification to get updates on your
                                account activities and more.
                              </p>
                            </div>
                            <label className="switch">
                              <Field
                                type="checkbox"
                                id="notifications"
                                name="settings.notifications"
                                className="form-check-input ms-2"
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                          <ErrorMessage
                            name="settings.notifications"
                            component="div"
                            className="text-danger"
                          />
                          <div className="notification-options">
                            <p>Show notifications for:</p>
                            <label>
                              <Field
                                type="checkbox"
                                id="notifications"
                                name="settings.commentsNotifications"
                                className="form-check-input ms-2"
                              />
                              <ErrorMessage
                                name="settings.commentsNotifications"
                                component="div"
                                className="text-danger"
                              />
                              <span>Comments and replies.</span>
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                id="notifications"
                                name="settings.newNotifications"
                                className="form-check-input ms-2"
                              />
                              <ErrorMessage
                                name="settings.newNotifications"
                                component="div"
                                className="text-danger"
                              />
                              <span>New messages.</span>
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                id="notifications"
                                name="settings.jobNotifications"
                                className="form-check-input ms-2"
                              />
                              <ErrorMessage
                                name="settings.jobNotifications"
                                component="div"
                                className="text-danger"
                              />
                              <span>Job updates.</span>
                            </label>
                            <label>
                              <Field
                                type="checkbox"
                                id="notifications"
                                name="settings.systemNotifications"
                                className="form-check-input ms-2"
                              />
                              <ErrorMessage
                                name="settings.systemNotifications"
                                component="div"
                                className="text-danger"
                              />
                              <span>System updates.</span>
                            </label>
                          </div>
                          <div className="save-button-parent text-end">
                            <button
                              type="submit"
                              className="custom-btn bg-blue large_font"
                              disabled={!dirty || isSubmitting}
                            >
                              {isSubmitting ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                "Save"
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              )}
              {activeTab === "tab2" && (
                <div className="container">
                  <div className="single-field">
                    <div className="row">
                      <div className="col-md-12 p-0">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            secondaryEmail: user?.secondaryEmail
                              ? [user?.secondaryEmail]
                              : [],
                            timeZone: user?.timeZone ? user?.timeZone : "",
                            hourFormat: user?.hourFormat
                              ? user?.hourFormat
                              : "",
                          }}
                          validationSchema={preferencesValidationSchema}
                          onSubmit={handleSubmitPreferences}
                        >
                          {({
                            dirty,
                            values,
                            setFieldValue,
                            touched,
                            errors,
                            isSubmitting,
                          }) => {
                            return (
                              <Form className="password-security-container">
                                <h2 className="settings-tab-ui-head d-flex align-items-center gap-3">
                                  <FaArrowLeftLong
                                    className="d-block d-md-none"
                                    onClick={tabContentMobileCloseHandler}
                                    style={{
                                      width: 22,
                                      height: 17,
                                      color: "#1a202c",
                                      borderRadius: 0,
                                      minWidth: "auto",
                                    }}
                                  />
                                  Preferences
                                </h2>
                                <hr className="settings-tab-ui-hr" />
                                <div className="sec1">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <div className="primary-email-section">
                                        <div className="sec2-setting-context">
                                          <div className="email-input-section">
                                            <label>Primary Email</label>
                                            <input
                                              type="email"
                                              disabled
                                              placeholder="Johnconor89@gmail.com"
                                              value={profile?.email || ""}
                                            />
                                            <FieldArray name="secondaryEmail">
                                              {({ push }) => (
                                                <>
                                                  {values.secondaryEmail?.map(
                                                    (_, index) => (
                                                      <div
                                                        className="email-input-section"
                                                        key={index}
                                                      >
                                                        <Field
                                                          name={`secondaryEmail[${index}]`}
                                                          type="email"
                                                          placeholder="Enter secondary email"
                                                        />
                                                        <ErrorMessage
                                                          name="secondaryEmail"
                                                          component="div"
                                                          className="text-danger"
                                                        />
                                                      </div>
                                                    )
                                                  )}

                                                  {/* Add another email button */}
                                                  {values.secondaryEmail
                                                    ?.length <
                                                    maxAdditionalEmails && (
                                                    <button
                                                      className="btn btn-link pt-0 px-0 change-password-link"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        push("");
                                                      }}
                                                    >
                                                      <img
                                                        src={AddBlueIcon}
                                                        alt="AddBlueIcon"
                                                      />
                                                      <span>
                                                        Add another email
                                                      </span>
                                                    </button>
                                                  )}
                                                </>
                                              )}
                                            </FieldArray>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="sec1">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <div className="primary-email-section">
                                        <div className="sec2-setting-context">
                                          <div className="password-section">
                                            <div className="dropdown-section">
                                              <div className="dropdown-sec1">
                                                <label>Time zone</label>
                                                <Dropdown
                                                  className="custom-dropdown settings-dropdown"
                                                  onSelect={(val) =>
                                                    setFieldValue(
                                                      "timeZone",
                                                      val
                                                    )
                                                  }
                                                >
                                                  <Dropdown.Toggle>
                                                    {timeZones?.find(
                                                      (zone) =>
                                                        zone.value ===
                                                        values.timeZone
                                                    )?.label ||
                                                      "Select Timezone"}
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    {timeZones?.map((zone) => (
                                                      <Dropdown.Item
                                                        key={zone.value}
                                                        eventKey={zone.value}
                                                      >
                                                        {zone.label}
                                                      </Dropdown.Item>
                                                    ))}
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                              <div className="dropdown-sec2">
                                                <label>Hour</label>
                                                <Dropdown
                                                  className="custom-dropdown settings-dropdown"
                                                  onSelect={(val) =>
                                                    setFieldValue(
                                                      "hourFormat",
                                                      val
                                                    )
                                                  }
                                                >
                                                  <Dropdown.Toggle>
                                                    {values?.hourFormat}
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="12">
                                                      12
                                                    </Dropdown.Item>
                                                    <Dropdown.Item eventKey="24">
                                                      24
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="save-button-parent text-end m-4">
                                    <button
                                      type="submit"
                                      disabled={!dirty || isSubmitting}
                                      className="btn-style large_font"
                                    >
                                      {isSubmitting ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        "Save"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "tab3" && (
                <div className="password-security-container">
                  <h2 className="settings-tab-ui-head   ">
                    <FaArrowLeftLong
                      className="d-block d-md-none"
                      onClick={tabContentMobileCloseHandler}
                      style={{
                        width: 22,
                        height: 17,
                        color: "#1A202C",
                        borderRadius: 0,
                        minWidth: "auto",
                      }}
                    />
                    Password & Security
                  </h2>
                  <hr className="settings-tab-ui-hr" />
                  <div className="sec2-setting-context">
                    <div className="password-section">
                      <label>Password</label>
                      <input
                        type="password"
                        placeholder="********"
                        value={currentPass}
                        onChange={(e) => {
                          setCurrentPassError(false);
                          setCurrentPass(e.target.value);
                        }}
                        className="col-lg-9 col-md-10"
                      />
                      {currentPassError && (
                        <span className="text-danger">
                          Please enter current password
                        </span>
                      )}

                      <button
                        onClick={handleOpenPassModel}
                        className="btn btn-link px-0 change-password-link"
                      >
                        Change password
                      </button>
                    </div>

                    <div className="other-login-methods">
                      <h3>Other login methods</h3>
                      {/* <div className="parent-div-text"> */}
                      <span className="login-google">Log in with Google</span>
                      <span className="login-google-subtext">
                        Connect your Google account for easy and quick logins
                      </span>
                      <button className="connect-google-button">
                        <img src={Google} alt="Google Icon" />
                        <span>Connect Google</span>
                      </button>
                      {/* </div> */}
                    </div>

                    <div className="logout-all-devices">
                      <h3>Log out of all devices</h3>
                      <span className="col-lg-9 col-md-10">
                        Log out of all active sessions across all devices,
                        including your current session. It may take up to 30
                        minutes for other devices to be logged out.
                      </span>
                      <button
                        className="logout-button"
                        onClick={() => d(userLogoutAsyncThunk())}
                      >
                        <span>Log out of all</span>
                      </button>
                    </div>
                    <div className="save-button-parent text-end m-4">
                      <a href="#" className="btn-style large_font">
                        Save
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "tab5" && (
                <PaymentTab
                  tabContentMobileCloseHandler={tabContentMobileCloseHandler}
                />
              )}
              {activeTab === "tab6" && (
                <div>
                  <div className="notification-settings">
                    <h2 className="settings-tab-ui-head d-flex align-items-center gap-3">
                      <FaArrowLeftLong
                        className="d-block d-md-none"
                        onClick={tabContentMobileCloseHandler}
                        style={{
                          width: 22,
                          height: 17,
                          color: "#1a202c",
                          borderRadius: 0,
                          minWidth: "auto",
                        }}
                      />
                      Account
                    </h2>
                  </div>

                  <hr className="settings-tab-ui-hr" />
                  <Formik
                    initialValues={{
                      firstName: creator?.firstName || "",
                      lastName: creator?.lastName || "",
                    }}
                    onSubmit={handleUpdateCreator}
                    validationSchema={updateCreatorSchema}
                  >
                    {({ values, handleSubmit, handleChange, handleBlur,errors,touched,dirty }) => (
                      <form action="#" onSubmit={handleSubmit} className="form sec2-setting-context">
                        <div className="form-group ">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="Name">First name</label>
                              <input
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                placeholder="John"
                              />
                              {errors.firstName && touched.firstName && <div className="error-msg">{errors.firstName}</div>}
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="Name">Last name</label>
                              <input
                                type="text"
                                name="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                placeholder="Doe"
                              />
                              {errors.lastName && touched.lastName && <div className="error-msg">{errors.lastName}</div>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="form-group ">
                              <label htmlFor="Name">Email</label>
                              <input
                                type="email"
                                name="email"
                                value={creator?.email}
                                disabled
                                className="form-control"
                                placeholder="email@example.com"
                              />
                            </div>
                          </div>
                        </div>
                        <button type="submit" disabled={!dirty || loading} className="btn-style" style={{display:"flex",alignItems:"end",justifyContent:"end"}}>{loading ? <Spinner animation="border" size="sm" /> : "Save"}</button>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {passModel && (
        <ChangePasswodModal
          currentPass={currentPass}
          isOpen={passModel}
          handleClose={handleClosePassModel}
        />
      )}
      <DeleteAccountModal
        deleteAccount={deleteAccount}
        handleCloseDeleteAccount={handleCloseDeleteAccount}
      />
    </>
  );
};

export default Settings;
