import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignForPortal, updateCampaignCountAsyncThunk, updateCampaignViewsAsyncThunk } from "../redux/pagesSlices/campaignSlice";
import { Link } from "react-router-dom";
import { getId } from "../utils/utils";

export default function TopShortBanner() {
  const [buttonClicked, setButtonClicked] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      visibility: "Creators",
    };
    dispatch(
      getCampaignForPortal({
        params,
      })
    );
  }, [dispatch]);
  const campaign = useSelector((state) => state?.campaign?.campaign);
  const campaignId = getId(campaign);

  const handleUpdateCampaignCount = () => {
    dispatch(
      updateCampaignCountAsyncThunk({
        id: campaignId,
      })
    );
  };
  const handleUpdateCampaignViews = () => {
    dispatch(
      updateCampaignViewsAsyncThunk({
        id: campaignId,
      })
    );
    setButtonClicked(true);
  };
  // console.log("campaign", campaign);

  return (
    <>
      {campaign?.status === "Active" && (
        <div className={`${buttonClicked ? "d-none" : ""} top-short-banner`}>
          <div className="inner-banner">
            <p className="white medium">{campaign?.campaignName} </p>
            <Link
              to={campaign?.buttonRoute}
              target="_blank"
              onClick={handleUpdateCampaignCount}
              className="btn-style banner-btn"
            >
              {campaign?.buttonText}
            </Link>
            <button
              className={`cancel-banner`}
              onClick={handleUpdateCampaignViews}
            >
              <RxCross2 />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
