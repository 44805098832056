import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// Start Campaign Slices
///////////////////////////////////////////////////

export const createCampaignAsyncThunk = createAsyncThunk(
  "campaign/createCampaignAsyncThunk",
  catchAsync(async ({ data, callback }, { dispatch, getState }) => {
    console.log(data);
    
    const response = await ApiRequests.createCampaign(data);
    if (callback) callback();
    dispatch(
      getCampaignsAsyncThunk({})
    )
    return response?.data;
  })
);

export const getCampaignsAsyncThunk = createAsyncThunk(
  "campaign/getCampaignsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getCampaigns(params);
    return response?.data;
  })
);

export const getCampaignAsyncThunk = createAsyncThunk(
  "campaign/getCampaignAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getCampaign(id);
    return response?.data;
  })
);

export const getCampaignsCountAsyncThunk = createAsyncThunk(
  "campaign/getCampaignCountAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getCampaignsCount(params);
    return response?.data;
  })
);

export const updateCampaignByIdAsyncThunk = createAsyncThunk(
  "campaign/updateCampaignByIdAsyncThunk",
  catchAsync(async ({ id, data, callback }, { dispatch, getState }) => {
    console.log("id",id);
    
    const response = await ApiRequests.updateCampaignById(id, data);
    if (callback) callback();
    dispatch(
      getCampaignsAsyncThunk({})
    )
    return response?.data;
  })
)

export const getCampaignForPortal = createAsyncThunk(
    "campaign/getCampaignForPortal",
    catchAsync(async ({params}, _) => {
      const response = await ApiRequests.getCampaignForPortal(params);
      return response?.data;
    })
)

export const updateCampaignCountAsyncThunk = createAsyncThunk(
  "campaign/updateCampaignCountAsyncThunk",
  catchAsync(async ({ id,callback }, { dispatch, getState }) => {
    const response = await ApiRequests.updateCampaignCount(id);
    if (callback) callback();
    dispatch(
      getCampaignsAsyncThunk({})
    )
    return response?.data;
  })
)
export const updateCampaignViewsAsyncThunk = createAsyncThunk(
  "campaign/updateCampaignCountAsyncThunk",
  catchAsync(async ({ id,callback }, { dispatch, getState }) => {
    const response = await ApiRequests.updateCampaignViews(id);
    if (callback) callback();
    dispatch(
      getCampaignsAsyncThunk({})
    )
    return response?.data;
  })
)

const initialState = {
  campaigns: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  campaignCount: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  campaign: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  order: "asce",
};

const campaignSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    // setSearchValue(state, action) {
    //   state.search = action.payload;
    // },
    // setOrderValue(state, action) {
    //   state.order = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignAsyncThunk.fulfilled, (state, action) => {
        state.campaign = action.payload;
      })
      .addCase(getCampaignAsyncThunk.pending, (state, action) => {
        state.campaign = null;
      })
      .addCase(getCampaignsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.campaigns = {
            ...action.payload,
            campaigns: state?.campaigns?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.campaigns = action.payload;
        }
      })
      .addCase(getCampaignsCountAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.campaignCount = {
            ...action.payload,
            campaignCount: state?.campaignCount?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.campaignCount = action.payload;
        }
      })
      .addCase(getCampaignForPortal.fulfilled, (state, action) => {
        state.campaign = action.payload;
      })
      .addCase(getCampaignForPortal.pending, (state, action) => {
        state.campaign = null;
      })
      .addMatcher(
        isAnyOf(
          ...reduxToolKitCaseBuilder([
            getCampaignAsyncThunk,
            getCampaignsAsyncThunk,
            createCampaignAsyncThunk,
            getCampaignsCountAsyncThunk,
            updateCampaignByIdAsyncThunk,
            getCampaignForPortal,
            updateCampaignCountAsyncThunk,
            updateCampaignViewsAsyncThunk
        ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default campaignSlice.reducer;
// export const { setOrderValue, setSearchValue } =
//   campaignSlice.actions;
