import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import VideoLivePricingsListsComponent from "./VideoLivePricingsListsComponent";
import pointerIcon from "../assets/images/icons/pointer-icon.svg";
import { getId, returnArray } from "../utils/utils";
import {
  getApplicationAsyncThunk,
  updateApplicationAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import { useDispatch } from "react-redux";

export default function EditProposalComponent({
  editModalState,
  editModalCloseHandler,
  proposalDetails,
}) {
  const dispatch = useDispatch();
  const [videoPrices, setVideoPrices] = useState([]);
  const [livePrices, setLivePrices] = useState([]);
  const [offeredVideoPrice, setOfferedVideoPrice] = useState([]);
  const [offeredLivePrice, setOfferedLivePrice] = useState([]);
  const resetVidePrices = () => {
    setVideoPrices(proposalDetails?.offeredVideoPrice ?? []);
    setOfferedVideoPrice([]);
  };
  const resetLivePrices = () => {
    setLivePrices(proposalDetails?.offeredLivePrice ?? []);
    setOfferedLivePrice();
  };

  useEffect(() => {
    if (videoPrices.length === 0) {
      setVideoPrices(proposalDetails?.offeredVideoPrice ?? []);
    }
    if (livePrices.length === 0) {
      setLivePrices(proposalDetails?.offeredLivePrice ?? []);
    }
  }, [proposalDetails, livePrices.length, videoPrices.length]);

  const handleUpdateProposal = () => {
    const cleanedVideoPrices = videoPrices.map(({ _id, id, ...rest }) => rest);
    const cleanedLivePrices = livePrices.map(({ _id, id, ...rest }) => rest);
    dispatch(
      updateApplicationAsyncThunk({
        id: getId(proposalDetails),
        data: {
          offeredLivePrice: returnArray(cleanedLivePrices),
          offeredVideoPrice: returnArray(cleanedVideoPrices),
        },
        callBack: () => {
          editModalCloseHandler();
          dispatch(getApplicationAsyncThunk(getId(proposalDetails)));
        },
      })
    );
  };

  return (
    <div className="edit-proposal-modal-wrapper custom-modal-wrapper">
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={editModalState}
        className="edit-proposal-modal custom-modal"
        onHide={editModalCloseHandler}
      >
        <Modal.Header editModalCloseHandler>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-wrappr">
            <div className="img-wrapper">
              <div className="img">
                <img src={pointerIcon} alt="pointerIcon" />
              </div>
            </div>
            <div className="content-wrapper">
              <h3>Edit Proposal</h3>
              <p className="medium medium-bold">
                Please enter a new price or select from your available options.
              </p>
            </div>
          </div>
          <div className="pricing-main-wrapper">
            <VideoLivePricingsListsComponent
              livePrices={livePrices}
              videoPrices={videoPrices}
              setVideoPrices={setVideoPrices}
              setLivePrices={setLivePrices}
              offeredVideoPrice={offeredVideoPrice}
              setOfferedVideoPrice={setOfferedVideoPrice}
              offeredLivePrice={offeredLivePrice}
              setOfferedLivePrice={setOfferedLivePrice}
              resetVidePrices={resetVidePrices}
              resetLivePrices={resetLivePrices}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to="#"
            className="btn-style transparent light large"
            onClick={editModalCloseHandler}
          >
            Cancel
          </Link>
          <Link
            to="#"
            className="btn-style large"
            onClick={handleUpdateProposal}
          >
            Update Proposal
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
