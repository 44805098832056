import React, { useEffect, useState } from "react";
import MyJobsPageHeader from "../components/MyJobsPageHeader";
import NoJobsComponent from "../components/NoJobsComponent";
import MyProposalsListing from "../components/MyProposalsListing";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicationAsyncThunk,
  getApplicationsAsyncThunk,
  getMyApplicationsAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import { RenderMap, returnArray } from "../utils/utils";
import PaginationComponent from "../components/PaginationComponent";
import SideBarComponent from "../components/SideBarComponent";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import { toggleSidebarMobile } from "../redux/pagesSlices/authSlice";
import NoProposals from "../assets/images/no-proposals.svg";
import upDown from '../assets/images/icons/up-down.png'
import colm from '../assets/images/icons/column.png'
import statuserr from '../assets/images/icons/status-err.png'
export default function MyProposals() {
  const dispatch = useDispatch();

  const setSidebarShow = useSelector((s) => s.auth.showSidebarOrNot);
  const { myApplications } = useSelector((s) => s.application);
  const params = useSelector(
    (s) => s.application?.paramsForThunk?.getMyApplicationsAsyncThunk
  );
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    promotionType: [],
    status: "All",
  });
  const [myJobsViewModal, setMyJobsViewModal] = useState(false);
  const [activeTabs, setActiveTabs] = useState("all");
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [sideBarOpen, setSideBarOpen] = useState(true);

  const handleClose = () => setMyJobsViewModal(false);
  const handleShow = () => setMyJobsViewModal(true);
  console.log("myApplications", myApplications);
  const activeTabHandler = (tab) => {
    setActiveTabs(tab);
  };

  const sideBarOpenHandler = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const goToPage = (page) => {
    const reqParams = { ...(params ? params : {}), page, sortBy };
    dispatch(getMyApplicationsAsyncThunk(reqParams));
  };
  const applications = useSelector((s) => s.application.myApplications);
  useEffect(() => {
    // pending, approved, rejected
    const params = {
      populate: "jobId",
      sortBy,
      ...(selectedFilters.promotionType?.length > 0 && {
        types: selectedFilters.promotionType,
      }),
      ...(selectedFilters.status &&
        selectedFilters.status !== "All" && {
          status: selectedFilters.status.toLowerCase(),
        }),
      ...(selectedFilters.categories?.length > 0 && {
        categories: selectedFilters.categories.filter((item) => item !== "All"),
      }),
    };
    if (search) params.search = search;
    dispatch(getMyApplicationsAsyncThunk({ ...params }));
  }, [dispatch, sortBy, search, selectedFilters]);
  const handleSearchChange = (e) => setSearch(e.target.value);

  return (
    <div className="main-inner-page-wrapper">
      <SideBarComponent
        sideBarOpen={sideBarOpen}
        sideBarOpenHandler={sideBarOpenHandler}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        dateFilter={true}
        clearAllFilters={() =>
          setSelectedFilters({
            categories: [],
            promotionType: [],
            status: "All",
          })
        }
        arrangementArray={[
          { name: "StatusFilter", type: "single" },
          { name: "PromotionType", type: "multiple" },
          { name: "Categories", type: "multiple" },
        ]}
      />
      <div className={`inner-page-wrapper ${sideBarOpen ? "active" : ""}`}>
        <div className="my-proposals-page">
          <MyJobsPageHeader
            title="My Proposals"
            sortBy={sortBy}
            setSortBy={setSortBy}
            search={search}
            handleSearchChange={handleSearchChange}
            containerFluid={true}
            //  jobsViewHandler={jobsViewHandler}
            //  jobsView={jobsView}
          />
          <div className={`inner-page-section ${myApplications?.totalResults < 1 ? 'bg-transparent' : ''}`}>
            <div className="my-proposals-listings">
              <div
                className="table-wrapper over_flow"
                style={{ minHeight: 500 }}
              >
                {myApplications?.totalResults < 1 && (
                  <table>
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "start",
                            paddingLeft: 133,
                          }}
                        >
                          <strong className="white small">Job Title</strong>
                        </th>
                        <th style={{ textAlign: "start" }}>
                          <strong className="white small">Brand Name</strong>
                        </th>
                        <th style={{ textAlign: "start" }}>
                          <strong className="white small">Proposal Type </strong>
                        </th>
                        <th style={{ textAlign: "start" }}>
                          <strong className="white small">Status</strong>
                        </th>
                        <th style={{ textAlign: "start" }}>
                          <strong className="white small">Applied Date</strong>
                        </th>
                        <th style={{ textAlign: "end" }}>
                          <strong className="white small">Action</strong>
                        </th>
                      </tr>
                    </thead>
                  </table>
                )}
                <ProviderErrorLoadingPagination
                  reducer="application"
                  action="getMyApplicationsAsyncThunk"
                  asyncThunk={getMyApplicationsAsyncThunk}
                  dataKey="myApplications"
                  Component={MyProposalsListing}
                  emptyMessage="No proposals found!"
                  emptyComponent={
                    <NoJobsComponent
                      image={NoProposals}
                      title="Oops! Nothing's here"
                      description="You have not applied to any jobs yet. Browse available jobs and start applying."
                      btnText="Browse Jobs"
                      route="/dashboard/find-jobs"
                      showBtn2={false}
                    />
                  }
                  pagination
                  Parent={({ children }) => (
                    <table>
                      {myApplications?.totalResults > 0 && (
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "start",
                                paddingLeft: 133,
                              }}
                            >
                              <strong className="white small">Job Title</strong>
                            </th>
                            <th style={{ textAlign: "start" }}>
                              <strong className="white small">
                                Brand Name
                              </strong>
                            </th>
                            <th style={{ textAlign: "start" }}>
                              <div className="proposal-updown">
                              <strong className="white small">
                                Proposal Type                           
                              </strong>
                              <img src={upDown} alt="img" />
                              </div>
                           
                            </th>
                            <th style={{ textAlign: "start" }}>
                            <div className="proposal-updown">
                              <strong className="white small">Status</strong>
                              <img src={statuserr} alt="img" />
                              </div>
                            </th>
                            <th style={{ textAlign: "start" }}>
                            <div className="proposal-updown">
                              <strong className="white small">
                                Applied Date
                              </strong>
                              <img src={colm} alt="img" />
                              </div>
                            </th>
                            <th style={{ textAlign: "end" }}>
                              <strong className="white small">Action</strong>
                            </th>
                          </tr>
                        </thead>
                      )}
                      <tbody>{children} </tbody>
                    </table>
                  )}
                  itemKey="application"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="overLay"
        className={`${setSidebarShow ? "active" : ""}`}
        onClick={() => dispatch(toggleSidebarMobile(false))}
      ></div>
    </div>
  );
}
