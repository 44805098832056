import React, { useEffect, useState } from "react";

import uploadBrandLogoIcon from "../../assets/images/icons/upload-brand-logo-icon.svg";
import Modal from "react-bootstrap/Modal";
import JobPostShopRates from "../JobPostShopRates";
import VideoLivePricingsListsComponent from "../VideoLivePricingsListsComponent";
import { useDispatch, useSelector } from "react-redux";
import { parseImagePathToURL } from "../../utils/globalFunctions";
import { updateProfile } from "../../redux/pagesSlices/authSlice";
import {
  updateCreatorAsyncThunk,
  updateCreatorAvatarAsyncThunk,
} from "../../redux/pagesSlices/creatorSlice";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

export default function EditProfileModal({
  editModalModal,
  editModalCloseHandler,
}) {
  const [modalTabs, setModalTabs] = useState("info");
  const modalTabsHandler = (tab) => {
    setModalTabs(tab);
  };
  const { profile, creator } = useSelector((s) => s.auth);

  const userProfileImage = profile?.avatar;
  //   const firstName = profile?.name;
  const userEmail = profile?.email;
  const bio = profile?.bio;
  const [email, setEmail] = useState(userEmail || "");
  const [livePrices, setLivePrices] = useState(creator?.livePrices || []);
  const [videoPrices, setVideoPrices] = useState(creator?.videoPrices || []);
  const resetVidePrices = () => {
    setVideoPrices(creator?.videoPrices ?? []);
  };
  const resetLivePrices = () => {
    setLivePrices(creator?.livePrices ?? []);
  };
  useEffect(() => {
    if (videoPrices.length === 0) {
      setVideoPrices(creator?.videoPrices ?? []);
    }
    if (livePrices.length === 0) {
      setLivePrices(creator?.livePrices ?? []);
    }
  }, [creator, livePrices.length, videoPrices.length]);

  const [originalLivePrices, setOriginalLivePrices] = useState([]);
  const [originalVideoPrices, setOriginalVideoPrices] = useState([]);

  useEffect(() => {
    setOriginalLivePrices(livePrices);
    setOriginalVideoPrices(videoPrices);
  }, []); // Runs whenever prices are reset

  // function splitName(fullName) {
  //   if (typeof fullName !== "string" || !fullName.trim()) {
  //     // Handle invalid input gracefully
  //     return { firstName: "", lastName: "" };
  //   }

  //   const parts = fullName.trim().split(" ", 2); // Split into up to two parts
  //   const firstName = parts[0] || ""; // Safeguard against undefined
  //   const lastName = parts[1] || ""; // Safeguard against missing last name
  //   return { firstName, lastName };
  // }
  // const [firstName, setFirstName] = useState(
  //   creator?.name?.split(" ")[0] || ""
  // );
  // const [lastName, setLastName] = useState(creator?.name?.split(" ")[1] || "");
  const [imageURLs, setImageURLs] = useState([]);
  const d = useDispatch();

  const handleUpdateUser = async (values) => {
    const formData = new FormData();
    if (values.avatar) {
      formData.append("avatar", values.avatar); // Single file upload
    }

    // await d(updateProfile({ data: formData }));

    await d(
      updateCreatorAsyncThunk({
        data: {
          // name: `${values.firstName} ${values.lastName}`,
          firstName: values?.firstName,
          lastName: values?.lastName,
          bio: values.bio,
        },
      })
    );
    if (values?.avatar) {
      await d(
        updateCreatorAvatarAsyncThunk({
          id: profile?.id,
          data: formData,
        })
      );
    }
  };

  const handleSavePrices = async () => {
    console.log("isLivePricesChanged", JSON.stringify(originalLivePrices));
    console.log("livePrices", JSON.stringify(livePrices));

    const isLivePricesChanged =
      JSON.stringify(originalLivePrices) !== JSON.stringify(livePrices);
    const isVideoPricesChanged =
      JSON.stringify(originalVideoPrices) !== JSON.stringify(videoPrices);

    if (!isLivePricesChanged && !isVideoPricesChanged) {
      console.log("No changes detected. API call skipped.");
      return;
    }

    const modifiedLivePrices = livePrices.map(({ _id, ...rest }) => rest);
    const modifiedVideoPrices = videoPrices.map(({ _id, ...rest }) => rest);

    console.log("Changes detected. Calling API...");
    await d(
      updateCreatorAsyncThunk({
        data: {
          livePrices: modifiedLivePrices,
          videoPrices: modifiedVideoPrices,
        },
      })
    );

    // Update original prices after successful save
    setOriginalLivePrices(livePrices);
    setOriginalVideoPrices(videoPrices);
  };

  const profileValidationSchema = Yup.object({
    firstName: Yup.string()
      .min(3, "First Name must be at least 3 characters")
      .required("First Name is required"),
    lastName: Yup.string().min(3, "Last Name must be at least 3 characters"),
    bio: Yup.string(),
  });

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={editModalModal}
      className="edit-profile-modal custom-modal"
      onHide={editModalCloseHandler}
    >
      <Modal.Header editModalCloseHandler closeButton>
        <Modal.Title id="contained-modal-title-center"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit-profile-modal-body-wrapper">
          <div className="edit-modal-sidebar-wrapaper">
            <div className="title">
              <strong className="large sami_bold dark">Edit Profile</strong>
            </div>
            <ul className="listings">
              <li className={`${modalTabs === "info" ? "active" : ""}`}>
                <a href="#" onClick={() => modalTabsHandler("info")}>
                  Account information
                </a>
              </li>
              <li className={`${modalTabs === "service" ? "active" : ""}`}>
                <a href="#" onClick={() => modalTabsHandler("service")}>
                  Services offered
                </a>
              </li>
            </ul>
          </div>
          <div className="modal-inner-content-wrapper">
            {modalTabs == "info" && (
              <>
                <Formik
                  initialValues={{
                    avatar: null,
                    firstName: creator?.firstName || "",
                    lastName: creator?.lastName || "",
                    bio,
                  }}
                  onSubmit={handleUpdateUser}
                  validationSchema={profileValidationSchema}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    errors,
                    touched,
                    dirty,
                  }) => {
                    console.log("errors", errors);
                    return (
                      <>
                        <form
                          action="#"
                          onSubmit={handleSubmit}
                          className="form"
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div className="field-wrapper">
                                <div className="form-group">
                                  <label
                                    htmlFor="name"
                                    className="text-center d-block"
                                  >
                                    Profile Photo
                                  </label>
                                  <div className="upload-image-input-type-file-wrapper">
                                    {userProfileImage ? (
                                      <>
                                        <div className="img-wrapper edit-profile-image">
                                          <label htmlFor="upload">
                                            <img
                                              src={
                                                values?.avatar
                                                  ? URL.createObjectURL(
                                                      values?.avatar
                                                    )
                                                  : parseImagePathToURL(
                                                      userProfileImage
                                                    )
                                              }
                                              alt="user profile"
                                              className="img"
                                            />
                                            <input
                                              type="file"
                                              id="upload"
                                              accept="image/png, image/jpg, image/jpeg, image/webp"
                                              style={{ display: "none" }}
                                              onChange={(e) => {
                                                const file = e.target.files[0];
                                                setFieldValue("avatar", file);
                                              }}
                                            />
                                          </label>
                                        </div>
                                      </>
                                    ) : (
                                      <label
                                        className="label"
                                        for="upload-brand"
                                      >
                                        <input
                                          type="file"
                                          name="avatar"
                                          id="upload-brand"
                                          className="visibility-hidden"
                                          accept="image/png, image/jpg, image/jpeg, image/webp"
                                          multiple
                                          onChange={(e) => {
                                            const file = e.target.files[0];
                                            setFieldValue("avatar", file);
                                          }}
                                        />
                                        <div className="img-wrapper">
                                          <img
                                            src={uploadBrandLogoIcon}
                                            alt="uploadBrandLogoIcon"
                                            className="upload-icon"
                                          />
                                        </div>
                                        <div className="text-wrapper">
                                          {values?.avatar ? (
                                            <p className="dark_text">
                                              {values?.avatar.name}
                                            </p>
                                          ) : (
                                            <>
                                              <p className="dark_text">
                                                Drop your image here, or{" "}
                                                <span className="blue">
                                                  browse
                                                </span>
                                              </p>
                                              <span className="ex-small">
                                                Supports: PNG, JPG, JPEG, WEBP
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </label>
                                    )}
                                  </div>
                                </div>
                                {errors.avatar && touched.avatar && (
                                  <span className="error-msg d-block text-center">
                                    Error Msg
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="field-wrapper">
                                <div className="form-group">
                                  <label htmlFor="name">First Name</label>
                                  <input
                                    type="text"
                                    value={values.firstName}
                                    name="firstName"
                                    onChange={handleChange("firstName")}
                                    onBlur={handleBlur("firstName")}
                                    className="form-control"
                                    placeholder="Enter your first name"
                                  />
                                </div>
                                {errors.firstName && touched.firstName && (
                                  <span className="error-msg">
                                    Your last name is missing
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="field-wrapper">
                                <div className="form-group">
                                  <label htmlFor="name">Last Name</label>
                                  <input
                                    type="text"
                                    value={values.lastName}
                                    name="lastName"
                                    onChange={handleChange("lastName")}
                                    onBlur={handleBlur("lastName")}
                                    className="form-control"
                                    placeholder="Enter your last name"
                                  />
                                </div>
                                {errors.lastName && touched.lastName && (
                                  <span className="error-msg">
                                    {errors.lastName}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="field-wrapper">
                                <div className="form-group">
                                  <label htmlFor="name">Email</label>
                                  <input
                                    type="text"
                                    value={email}
                                    disabled
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control"
                                    placeholder="Enter your email address"
                                  />
                                </div>
                                {!email && (
                                  <span className="error-msg">Error Msg</span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="field-wrapper">
                                <div className="form-group">
                                  <label htmlFor="name">
                                    What’s Unique About You
                                  </label>
                                  <textarea
                                    type="text"
                                    name="bio"
                                    onChange={handleChange("bio")}
                                    value={values.bio}
                                    className="form-control"
                                    placeholder="Describe your content"
                                  />
                                </div>
                                {errors.bio && touched.bio && (
                                  <span className="error-msg">Error Msg</span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="field-wrapper">
                                <div className="form-group">
                                  <label htmlFor="name" className="mb-0">
                                  Categories
                                  </label><br />
                                  <span className="medium-bold mb-1">Delete or type to add more categories.</span>
                                  <input
                                    type="text"                                 
                                    className="form-control"
                                    placeholder="Type to add"
                                  />
                                </div>
                                {errors.bio && touched.bio && (
                                  <span className="error-msg">Error Msg</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>

                        <div className="btn-wrapper d-flex flex-end">
                          <button
                            className="btn-style auto_width "
                            type="button"
                            disabled={!dirty}
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    );
                  }}
                </Formik>
              </>
            )}
            {modalTabs == "service" && (
              <>
                <div className="service-tab-content-wrapper">
                  <span className="title d-block mb-4 dark">
                    <span className={'dark medium-bold'}>Service offering</span>
                  </span>
                  <div className="pricing-main-wrapper">
                    <VideoLivePricingsListsComponent
                      livePrices={livePrices}
                      videoPrices={videoPrices}
                      setVideoPrices={setVideoPrices}
                      setLivePrices={setLivePrices}
                      resetVidePrices={resetVidePrices}
                      resetLivePrices={resetLivePrices}
                    />
                  </div>
                  <div className="btn-wrapper d-flex flex-end gap-2">
                    <a
                      href="#"
                      onClick={editModalCloseHandler}
                      className="btn-style transparent"
                    >
                      Cancel
                    </a>

                    <button
                      className="btn-style auto_width "
                      onClick={handleSavePrices}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
