import React, { useEffect, useState } from "react";

import jobImg from "../assets/images/job-img-1.svg";
import mapIcon from "../assets/images/icons/map-icon.svg";
import clockFillIcon from "../assets/images/icons/clock-fill-icon.svg";
import videoFileIcon from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import statusIcon from "../assets/images/icons/status-icon.svg";
import chatIcon from "../assets/images/icons/chat-icon.svg";
import envelopIcon from "../assets/images/icons/envelop-fill-icon.svg";
import { Link, useParams, useSearchParams } from "react-router-dom";
import TrackJobsStepsContent from "../components/TrackJobsStepsContent";
import LinkTrackingUrlModal from "../components/LinkTrackingUrlModal";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationWithStatsAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import LoaderComponent from "../components/LoaderComponent";
import NoDataComponent from "../components/NoDataComponent";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { dateFormat } from "../utils/format";
import moment from "moment";

export default function TrackProjectPage() {
  const d = useDispatch();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");

  const proposalDetails = useSelector((s) => s.application.application);
  const loading = useSelector(
    (s) => s.application?.loadings?.getApplicationWithStatsAsyncThunk
  );
  useEffect(() => {
    if (jobId) d(getApplicationWithStatsAsyncThunk(jobId));
  }, [d, jobId]);

  const steps = [
    { id: 1, name: "Purchase product" },
    { id: 2, name: "Content creation" },
    { id: 3, name: "Completion" },
    { id: 4, name: "Payout" },
  ];

  const getNextAction = () => {
    const status = proposalDetails?.status;
    const isPaymentSent = proposalDetails?.paymentInfo?.hasSent;
    const isSampleSent = proposalDetails?.sampleInfo?.curr_status === 100;
    const application = proposalDetails;
    const isContentCreated =
      (application?.videoPrice?.count &&
        application?.livePrice?.count &&
        proposalDetails?.jobProgressInfo?.video_count >=
          application.videoPrice.count &&
        proposalDetails?.jobProgressInfo?.live_count >=
          application.livePrice.count) ||
      (application?.videoPrice?.count &&
        !application?.livePrice?.count &&
        proposalDetails?.jobProgressInfo?.video_count >=
          application.videoPrice.count) ||
      (application?.livePrice?.count &&
        !application?.videoPrice?.count &&
        proposalDetails?.jobProgressInfo?.live_count >=
          application.livePrice.count);

    // Determine the next action based on the conditions
    if (proposalDetails?.sampleStatus !== "received") return steps[1].name; // "Purchase product"
    if (!isContentCreated) return steps[2].name; // "Completion"
    if (!proposalDetails?.creatorPaymentConfirmed) return "Confirm payment";

    return "All steps completed";
  };

  const getStepClass = (index) => {
    const application = proposalDetails;
    const isContentCreated =
      (application?.videoPrice?.count &&
        application?.livePrice?.count &&
        proposalDetails?.jobProgressInfo?.video_count >=
          application.videoPrice.count &&
        proposalDetails?.jobProgressInfo?.live_count >=
          application.livePrice.count) ||
      (application?.videoPrice?.count &&
        !application?.livePrice?.count &&
        proposalDetails?.jobProgressInfo?.video_count >=
          application.videoPrice.count) ||
      (application?.livePrice?.count &&
        !application?.videoPrice?.count &&
        proposalDetails?.jobProgressInfo?.live_count >=
          application.livePrice.count);

    if (proposalDetails?.sampleStatus !== "received") {
      if (index < 1) return "step-name-wrapper active";
      return "step-name-wrapper";
    }
    if (!isContentCreated) {
      if (index < 1) return "step-name-wrapper complete";
      if (index === 1) return "step-name-wrapper active";
      return "step-name-wrapper";
    }

    if (!proposalDetails?.brandApproval) {
      if (index < 2) return "step-name-wrapper complete";
      if (index === 2) return "step-name-wrapper active";
      return "step-name-wrapper";
    }
    if (index === 3) return "step-name-wrapper active";

    return "step-name-wrapper";
  };
  if (loading) return <LoaderComponent />;
  if (!proposalDetails)
    return (
      <NoDataComponent
        title="No Job Found"
        description="No job tracking details found with this id or application id"
        primaryActionText="Go to Dashboard"
        primaryActionLink="/dashboard"
        secondaryActionText="Go to My jobs"
        secondaryActionLink="/dashboard/my-jobs"
      />
    );
  return (
    <div className="inner-page-wrapper active m-0">
      <div className="track-project-page">
        <div className="track-project-page-inner">
          <div className="job-top-title-wrapper">
            <div className="container large">
              <div className="row">
                <div className="col-md-7 mb-4 mb-md-0">
                  <div className="title-wrapper">
                    <h2 className="white">
                      {proposalDetails?.jobId?.jobTitle}
                    </h2>
                    <div className="main-wrapper">
                      <div className="img-wrapper">
                        <img
                          src={
                            proposalDetails?.jobId?.image[0]
                              ? parseImagePathToURL(
                                  proposalDetails?.jobId?.image[0]
                                )
                              : jobImg
                          }
                          alt="jobImg"
                        />
                      </div>
                      <div className="content-wrapper">
                        <div className="text">
                          <strong className="name medium white">
                            <Link to="#" className="white">
                              {proposalDetails?.jobId?.brandName}
                            </Link>
                          </strong>
                          <div className="location">
                            <img
                              src={mapIcon}
                              alt="mapIcon"
                              className="white-icon"
                            />
                            <p className="medium medium-bold white">
                              {proposalDetails?.jobId?.location ||
                                "United States"}
                            </p>
                          </div>
                        </div>
                        <div className="text">
                          <div className="link">
                            <p className="medium medium-bold m-0 white">
                              <Link to="#">
                                {proposalDetails?.jobId?.tiktokShopUrl}
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="top-bar-cards-wrapper">
                    <div className="card-wrapper">
                      <h3 className="white">
                        {moment(proposalDetails?.endTime).diff(
                          moment(),
                          "days"
                        )}
                      </h3>
                      <div className="bottom">
                        <img
                          src={clockFillIcon}
                          alt="clockFillIcon"
                          className="white-icon"
                        />
                        <span className="ex-small white">Days to deadline</span>
                      </div>
                    </div>
                    <div className="card-wrapper">
                      <h3 className="white">
                        {proposalDetails?.jobProgressInfo?.video_count || 0}/
                        {proposalDetails?.videoPrice?.count}
                      </h3>
                      <div className="bottom">
                        <img
                          src={videoFileIcon}
                          alt="videoFileIcon"
                          className="white-icon"
                        />
                        <span className="ex-small white">Videos</span>
                      </div>
                    </div>
                    <div className="card-wrapper">
                      <h3 className="white">
                        {proposalDetails?.jobProgressInfo?.live_count || 0}/
                        {proposalDetails?.livePrice?.count}
                      </h3>
                      <div className="bottom">
                        <img
                          src={liveIcon}
                          alt="liveIcon"
                          className="white-icon"
                        />
                        <span className="ex-small white">Live</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="track-project-steps-main-wrapper">
                {steps.map((step, index) => (
                  <div key={step.id} className={getStepClass(index)}>
                    <h3 className="num">{step.id}.</h3>
                    <strong className="white">{step.name}</strong>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="track-project-short-info-wrapper">
            <div className="container large">
              <div className="inner-section-wrapper">
                <div className="text-wrapper">
                  <div className="title">
                    <img src={statusIcon} alt="statusIcon" />
                    <span className="medium-bold">Status</span>
                  </div>
                  <p>{proposalDetails?.status}</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={statusIcon} alt="statusIcon" />
                    <span className="medium-bold">Next Action</span>
                  </div>
                  <p>{getNextAction()}</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={statusIcon} alt="statusIcon" />
                    <span className="medium-bold">Content Type</span>
                  </div>
                  <p>{proposalDetails?.jobId?.types.join(",")}</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={statusIcon} alt="statusIcon" />
                    <span className="medium-bold">Amount</span>
                  </div>
                  <p>
                    ${Number(proposalDetails?.paymentInfo?.amount).toFixed(1)}
                  </p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={statusIcon} alt="statusIcon" />
                    <span className="medium-bold">Start Date</span>
                  </div>
                  <p>{dateFormat(proposalDetails?.createdAt)}</p>
                </div>
                <div className="text-wrapper">
                  <div className="title">
                    <img src={statusIcon} alt="statusIcon" />
                    <span className="medium-bold">Completion Date</span>
                  </div>
                  <p>
                    {proposalDetails?.completedAt
                      ? dateFormat(proposalDetails?.completedAt)
                      : "N/A"}
                  </p>
                </div>
                <div className="text-wrapper">
                  <div className="title column-direction">
                    <Link
                      to={`/dashboard/messages?id=${
                        proposalDetails?.brandId?.id
                      }&jobId=${proposalDetails?.id || proposalDetails?._id}`}
                    >
                      <div className="img-wrapper">
                        <img
                          src={envelopIcon}
                          alt="envelopIcon"
                          className="white-icon"
                        />
                      </div>
                      <span className="medium-bold">Message brand</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="track-project-main-content-section">
            <div className="container large">
              <div className="row">
                <div className="col-md-8">
                  <div className={"main-steps-content-wrapper"}>
                    <div className="main-title-wrapper">
                      <h3>Track Your Project Progress</h3>
                      <p className="medium medium-bold">
                        Stay on top of every step from start to finish. Easily
                        monitor your progress and take action to complete each
                        task on time.
                      </p>
                    </div>

                    <TrackJobsStepsContent proposalDetails={proposalDetails} />
                  </div>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>

          {/* <button onClick={handlePrevious} disabled={currentStep === 0}>
                        Previous
                    </button>
                    <button onClick={handleNext} disabled={currentStep === 6}>
                        Next
                    </button> */}

          <div className="contact-us-in-trouble-wrapper">
            <div className="container large">
              <div className="inner-wrapper">
                <div className="row">
                  <div className="col-md-8 align-self-center">
                    <div className="content-wrapper">
                      <div className="img-wrapper">
                        <img src={chatIcon} alt="chatIcon" />
                      </div>
                      <div className="text-wrapper">
                        <strong className="medium">Having trouble?</strong>
                        <p className="medium medium-bold">
                          Feel free to contact us and we will always help you
                          through the process
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 align-self-center">
                    <div className="btn-wrapper">
                      <a href="#" className="btn-style white-bg">
                        Contact us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
